// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Icon,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  Select,
  Grid,
  GridItem,
} from "@chakra-ui/react";

// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { gql, useQuery, useMutation } from "@apollo/client";
import { TbTrashX } from "react-icons/tb";

export const CREATE_PRODUCT_PRICE_GQL = gql`
    mutation createProductPrice($input: CreateProductPriceInput!){
      createProductPrice(input:$input){
            id
            price
            customer{
                id
                name
            }
        }
    }`

export const UPDATE_PRODUCT_PRICE_GQL = gql`
    mutation updateProductPrice($input: UpdateProductPriceInput!){
        updateProductPrice(input:$input){
          id
          price
          supplementPrice
          supplementOverSqMeters
          supplementSqMeterInterval
          customer{
              id
              name
          }
        }
    }`

export const GET_PRODUCT_PRICE_GQL = gql`
    query productPrices($id: ID!){
      productPrices(productId:$id){
          id
          price
          supplementPrice
          supplementOverSqMeters
          supplementSqMeterInterval
          isEstateOrderPrice
          customer{
              id
              name
          }
        }
        getCustomers {
            id
            name
        }
    }`

const ProductPrices = ({ productId }) => {
  const [priceObj, setPriceObj] = useState(null);
  const cancelRef = React.useRef();
  const cancelRefUpdateDataWarning = React.useRef();
  const customerRef = React.useRef();
  const priceRef = React.useRef();
  const supplementPriceRef = React.useRef();
  const supplementOverSqMetersRef = React.useRef();
  const supplementSqMeterIntervalRef = React.useRef();
  const cancelRefPriceDialog = React.useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenUpdateDataWarning, onOpen: onOpenUpdateDataWarning, onClose: onCloseUpdateDataWarning } = useDisclosure();
  const { isOpen: isOpenPriceDialog, onOpen: onOpenPriceDialog, onClose: onClosePriceDialog } = useDisclosure();

  const textColor = useColorModeValue("gray.700", "white");

  const [createProductPrice] = useMutation(CREATE_PRODUCT_PRICE_GQL, { refetchQueries: [GET_PRODUCT_PRICE_GQL] });
  const [updateProductPrice] = useMutation(UPDATE_PRODUCT_PRICE_GQL, { refetchQueries: [GET_PRODUCT_PRICE_GQL] });

  const { loading, error, data } = useQuery(GET_PRODUCT_PRICE_GQL, {
    variables: { id: productId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting prices from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting prices from server (2)</p>;
  }

  const editPrices = (priceId) => {
    //find price object
    let price = data.productPrices.find((x) => x.id === priceId);
    setPriceObj(price);
    onOpenPriceDialog();
  };

  const deletePrice = (priceId) => {
    let price = data.productPrices.find((x) => x.id === priceId);
    setPriceObj(price);
    onOpen();
  };

  const deleteRow = () => {
    onClose();
    let inputData = {} as any;
    inputData.id = priceObj.id;
    inputData.deleted = true;

    updateProductPrice({
      variables: { input: inputData },
    });

    setPriceObj(null);
  };

  const addNewPrice = () => {
    setPriceObj(null);
    onOpenPriceDialog();
  };

  const handleSaveProduct = () => {
    if (priceObj === null) {
      //create
      let inputData = {} as any;
      inputData.productId = productId;
      inputData.customerId = (customerRef.current as HTMLSelectElement).value === "" ? null : (customerRef.current as HTMLSelectElement).value;
      inputData.price = parseInt((priceRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;

      if (priceObj && priceObj.isEstateOrderPrice) {
        inputData.supplementPrice = parseInt((supplementPriceRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
        inputData.supplementSqMeterInterval = parseInt((supplementSqMeterIntervalRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
        inputData.supplementOverSqMeters = parseInt((supplementOverSqMetersRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
      }
      createProductPrice({
        variables: { input: inputData },
      });
    } else {
      //update
      let inputData = {} as any;
      inputData.id = priceObj.id;
      inputData.deleted = false;
      inputData.customerId = (customerRef.current as HTMLSelectElement).value === "" ? null : (customerRef.current as HTMLSelectElement).value;
      inputData.price = parseInt((priceRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
      if (priceObj && priceObj.isEstateOrderPrice) {
        inputData.supplementPrice = parseInt((supplementPriceRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
        inputData.supplementSqMeterInterval = parseInt((supplementSqMeterIntervalRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
        inputData.supplementOverSqMeters = parseInt((supplementOverSqMetersRef.current as HTMLInputElement).value); // priceRef.current ? parseInt(priceRef.current.value): 0;
      }
      updateProductPrice({
        variables: { input: inputData },
      });

      setPriceObj(null);
      onCloseUpdateDataWarning();
    }

    onClosePriceDialog();

  }

  return (
    <div>
      <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
        <CardHeader p="12px 5px" mb="12px" justifyContent="space-between">
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Priser
            </Text>
          </Flex>
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text fontSize="12px" color={textColor} fontWeight="bold" cursor="pointer" onClick={() => addNewPrice()}>
              Tilføj ny pris
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px" height={"100%"}>
          <TableContainer w="100%">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Kunde</Th>
                  <Th isNumeric>Pris</Th>
                  <Th>Tillæg</Th>
                  <Th w="20px">Edit</Th>
                  <Th w="20px">Slet</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.productPrices.map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.customer !== null ? <Td>{row.customer.name}</Td> : <Td>-</Td>}
                      <Td isNumeric>{row.price}</Td>
                      {row.supplementPrice > 0 ? <Td w={'400px'} >{row.supplementPrice} kr. pr. {row.supplementSqMeterInterval} m<sup>2</sup> over {row.supplementOverSqMeters} m<sup>2</sup></Td> : <Td>-</Td>}
                      <Td>
                        <Icon as={FaRegEdit} me="10px" style={{ cursor: "pointer" }} onClick={() => editPrices(row.id)} />
                      </Td>
                      <Td>
                        <Icon as={TbTrashX} me="10px" style={{ cursor: "pointer" }} onClick={() => deletePrice(row.id)} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet pris
            </AlertDialogHeader>
            <AlertDialogBody>Er du sikker?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteRow} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenUpdateDataWarning} leastDestructiveRef={cancelRefUpdateDataWarning} onClose={onCloseUpdateDataWarning}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Opdatere priser
            </AlertDialogHeader>
            <AlertDialogBody>
              Du er ved at ændre på priserne, som kan have stor indflydelse på afregningen tilbage i tiden.
              <br />
              Som udgangspunkt bør man aldrig ændre priser tilbage i tiden. Men oprette evt. nye priser fremadrettet.
              <br />
              <br />
              Er du sikker på at du vil opdatere?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefUpdateDataWarning}
                onClick={() => {
                  setPriceObj(null);
                  onCloseUpdateDataWarning();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={handleSaveProduct} ml={3}>
                Opdater
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isOpen={isOpenPriceDialog} leastDestructiveRef={cancelRefPriceDialog} onClose={onClosePriceDialog}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {priceObj ? 'Ændre pris' : 'Tilføj pris'}
            </AlertDialogHeader>
            <AlertDialogBody>
              {priceObj ? 'Du er ved at ændre en pris på et produkt.' : 'Du er ved at tilføje en pris til et produkt.'}
              <br />
              <br />
              <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                <GridItem display="flex" alignItems={"center"} colSpan={2}>
                  <p>Vælg kunde:</p>
                </GridItem>
                <GridItem colSpan={3}>
                  <Select
                    multiple={false}
                    ref={customerRef}
                    defaultValue={(priceObj && priceObj.customer) ? priceObj.customer.id : ""}
                  >
                    <option key={null} value="">
                      Alle
                    </option>
                    {data.getCustomers.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                </GridItem>

                <GridItem width={100} display="flex" alignItems={"center"} colSpan={2}>
                  <p>Pris:</p>
                </GridItem>
                <GridItem colSpan={3}>
                  <Input ref={priceRef} type="number" name="price" defaultValue={priceObj ? priceObj.price : '0'} />
                </GridItem>
                {priceObj && priceObj.isEstateOrderPrice && (
                  <>
                    <GridItem colSpan={5} textAlign={'center'}><b>Advanceret</b></GridItem>

                    <GridItem width={100} display="flex" alignItems={"center"} colSpan={2}>
                      <p>Tillægspris:</p>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <Input ref={supplementPriceRef} type="number" name="supplementPrice" defaultValue={priceObj ? priceObj.supplementPrice + '' : '0'} />
                    </GridItem>

                    <GridItem width={100} display="flex" alignItems={"center"} colSpan={2}>
                      <p>pr. x m<sup>2</sup>:</p>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <Input ref={supplementSqMeterIntervalRef} type="number" name="supplementSqMeterInterval" defaultValue={(priceObj && priceObj.supplementSqMeterInterval) ? priceObj.supplementSqMeterInterval + '' : '0'} />
                    </GridItem>

                    <GridItem width={100} display="flex" alignItems={"center"} colSpan={2}  >
                      <p style={{ whiteSpace: 'nowrap' }}>over x antal m<sup>2</sup>:</p>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <Input ref={supplementOverSqMetersRef} type="number" name="supplementOverSqMeters" defaultValue={(priceObj && priceObj.supplementOverSqMeters) ? priceObj.supplementOverSqMeters + '' : '0'} />
                    </GridItem>
                  </>
                )}
              </Grid>
              <br />
              <p></p>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRefPriceDialog}
                onClick={() => {
                  onClosePriceDialog();
                }}
              >
                Fortryd
              </Button>
              <Button colorScheme="green" onClick={handleSaveProduct} ml={3}>
                {priceObj ? 'Gem' : 'Opret'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export default ProductPrices;