// Chakra imports
import {
  Flex, ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from "@chakra-ui/react";
import React from "react";
import './messageViewImageMarker.css';
import * as markerjs2 from 'markerjs2';

interface IProps {
  isVisible: boolean;
  media: any;
  onClose: any;
}

const MessageViewImageMarker: React.FC<IProps> = ({ media, onClose, isVisible }) => {
  const imgRef = React.createRef<HTMLImageElement>();
  let markerArea = null;
  if (!isVisible) {
    return null;
  }

  const CloseMessageViewImageMarker = () => {
    if (markerArea) {
      markerArea.close();
    }
    onClose(null);
  }

  const UpdateMediaInMessage = () => {
    if (markerArea) {
      markerArea.blur();
      //add overlay to media
      let newMedia = { ...media };
      //convert svg object to string
      const svgStr = new XMLSerializer().serializeToString(markerArea.markerImage);
      newMedia.markerOverLay = svgStr;

      console.log('newMedia', newMedia);
      markerArea.close();
      onClose(newMedia);
    } else {
      onClose(null);
    }
  }

  const handleShowMarkerArea = () => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      markerArea = new markerjs2.MarkerArea(imgRef.current);
      markerArea.uiStyleSettings.zoomButtonVisible = true;
      markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      //markerArea.addEventListener("render", (event) => {alert('render')});

      markerArea.targetRoot = document.getElementsByClassName('markerjs-container')[0] as HTMLElement;

      markerArea.settings.defaultText = 'Aa';
      markerArea.settings.displayMode = 'popup';

      markerArea.show();

      //remove the marekerjs save and close button (html element title: title="Save changes")
      const markerjsUpdateButton = document.querySelector('div[title="Save changes"]');
      if (markerjsUpdateButton) {
        markerjsUpdateButton.remove();
      }

      const markerjsSaveButton = document.querySelector('div[title="Save and close"]');
      if (markerjsSaveButton) {
        //remove all event listeners
        const markerjsSaveButtonClone = markerjsSaveButton.cloneNode(true);
        markerjsSaveButton.parentNode.replaceChild(markerjsSaveButtonClone, markerjsSaveButton);

        markerjsSaveButtonClone.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          UpdateMediaInMessage();
        });
        //  markerjsSaveButton.remove();
      }

      const markerjsCloseButton = document.querySelector('div[title="Close"]');
      if (markerjsCloseButton) {
        //  markerjsCloseButton.remove();
        const markerjsCloseButtonClone = markerjsCloseButton.cloneNode(true);
        markerjsCloseButton.parentNode.replaceChild(markerjsCloseButtonClone, markerjsCloseButton);

        markerjsCloseButtonClone.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          CloseMessageViewImageMarker();
        });

      }

      markerArea.logoUI.remove();
    }
  }

  return (

    <Modal onClose={CloseMessageViewImageMarker} size="xl" isOpen={true}>
      <ModalOverlay />
      <ModalContent maxW="950px">
        {media.markerOverLay ? (
          <ModalHeader>Medie</ModalHeader>
        ) : (
          <ModalHeader>Marker medie</ModalHeader>
        )}
        <ModalCloseButton />
        <ModalBody>
          {media.markerOverLay ? (
            <>
              <div className="markerjs-container-view">
                <img
                  className="markerjs-container-view-image"
                  src={media.thumpnailUrl}
                  alt="image"
                />
                <div className="markerjs-container-view-overlay" dangerouslySetInnerHTML={{ __html: media.markerOverLay }}></div>
              </div>
            </>
          ) : (
            <Flex flexDirection={'row'} flexWrap={'wrap'} height={'690px'} maxHeight={'690px'} justifyContent={'center'}>
              <div className="markerjs-container">
                <img ref={imgRef}
                  src={media.thumpnailUrl}
                  style={{ maxWidth: '1000px' }}
                  alt="image"
                  onLoad={() => handleShowMarkerArea()}
                />
              </div>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter>
          {!media.markerOverLay && (
            <>
              <Button onClick={CloseMessageViewImageMarker}>Annuller</Button>
              <Button colorScheme='green' onClick={UpdateMediaInMessage} ml={3}>OK</Button>
            </>
          )}
        </ModalFooter>

      </ModalContent>
    </Modal>
  )
}

export default MessageViewImageMarker;