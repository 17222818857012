// Chakra imports
import {
    Flex, useColorModeValue, Text,
    Input,
    useToast,
    Tooltip,
    Image,
    Checkbox,
    Grid,
    GridItem
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import Header from "../../../../../components/Headers/Header";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { useQuery, useMutation, gql } from "@apollo/client";
//import MindworkingLogoLight from "./../../../../../assets/img/Mindworking-logo-light.svg";
import magnamulti_logo from "./../../../../../assets/img/magnamulti_logo.png";
import './../integrations.css';
import { FaInfoCircle } from "react-icons/fa";


const updatePhotographyCompanyInfoGql = gql`
  mutation UpdatePhotographyCompanyInput($input: UpdatePhotographyCompanyInput!) {
    updatePhotographyCompany(input: $input) {
      id
    }
  }
`;


const CUSTOMERS_GQL = gql`
query customers {
    getPhotographyCompany {
        id
        planningMaxDistanceFromBase
        planningMaxDistanceBetweenJobs
        planningMaxDistanceBeforeWorkHour
        planningMaxDistanceAfterWorkHour
    }
    getCustomers {
        id
        name
        autobookingEnabled
    }
}`

const UPDATE_CUSTOMER_GQL = gql`
mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input:$input){
        id
    }
}`

function Magnamulti_Autobooking() {
    const toast = useToast()
    const [updateCompanyInfo] = useMutation(updatePhotographyCompanyInfoGql);
    //Colors
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [updateCustomer] = useMutation(
        UPDATE_CUSTOMER_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const handleCustomerEnabledChange = (e, customerId) => {
        const value = e.target.checked;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    autobookingEnabled: value
                }
            }
        }).then(() => {
            toast({
                title: "Butikken er opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch(() => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af Butikken.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    const updateCompanyData = (field, value) => {

        const dataObj = {}
        dataObj['id'] = data.getPhotographyCompany.id;
        dataObj[field] = parseInt(value);

        updateCompanyInfo({ variables: { input: dataObj } })
            .then(() => {
                toast({
                    title: "Ændringen er blevet gemt.",
                    description: "",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: "Der opstod en fejl, ændringen blev ikke gemt.",
                    description: "",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            });
    };

    return (
        <div className="AutoBookingSettings">
            <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Indstillinger - Automatisk booking"}
                    description={'Opsætning af automatisk booking'}
                    showBackButton={true}
                />

                <Card p="16px" my={{ sm: "0px", xl: "0px" }}>
                    <CardHeader p="12px 5px" justifyContent="space-between" >
                        <Flex justifyContent="center" align="center" style={{ width: "100%" }}>
                            <Image
                                src={magnamulti_logo}
                                alt='Magna Multi logo'
                                maxWidth={'320px'}
                            />
                        </Flex>
                    </CardHeader>
                </Card>
                
                <Card p="16px" my={{ sm: "24px", xl: "24px" }}>
                    <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                        <Flex direction="column" style={{ width: "100%" }}>
                            <Grid templateColumns={{  sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xl: "repeat(2, 1fr)", "2xl": "repeat(4, 1fr)"}} rowGap={'0px'} gap={'20px'} width={'100%'}>
                                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                    <Flex align="center" mb="18px" width={'100%'}>
                                        <Grid templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"} >
                                                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                                                    Maks afstand fra base (km):{" "}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={5} rowSpan={1} >
                                                <Input
                                                    
                                                    variant="flushed"
                                                    type="number"
                                                    name="planningMaxDistanceFromBase"
                                                    defaultValue={data.getPhotographyCompany.planningMaxDistanceFromBase}
                                                    onBlur={(e) => updateCompanyData('planningMaxDistanceFromBase', e.currentTarget.value)}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                    <Flex align="center" mb="18px" width={'100%'}>
                                        <Grid templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                                                    Maks afstand mellem aftaler (km):{" "}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={5} rowSpan={1} >
                                                <Input
                                                    
                                                    variant="flushed"
                                                    type="number"
                                                    name="planningMaxDistanceBetweenJobs"
                                                    defaultValue={data.getPhotographyCompany.planningMaxDistanceBetweenJobs}
                                                    onBlur={(e) => updateCompanyData('planningMaxDistanceBetweenJobs', e.currentTarget.value)}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>

                                    <Flex align="center" mb="18px" width={'100%'}>
                                        <Grid templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                                                    Maks kørsel før arbejdstid (km):{" "}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={5} rowSpan={1} >
                                                <Input
                                                   
                                                    variant="flushed"
                                                    type="number"
                                                    name="planningMaxDistanceBeforeWorkHour"
                                                    defaultValue={data.getPhotographyCompany.planningMaxDistanceBeforeWorkHour}
                                                    onBlur={(e) => updateCompanyData('planningMaxDistanceBeforeWorkHour', e.currentTarget.value)}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                    <Flex align="center" mb="18px" width={'100%'}>
                                        <Grid templateColumns={{ sm: "1fr", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                                                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"250px"} me="10px" whiteSpace={"nowrap"}>
                                                    Maks kørsel efter arbejdstid (km):{" "}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={5} rowSpan={1} >
                                                <Input
                                                    
                                                    variant="flushed"
                                                    type="number"
                                                    name="planningMaxDistanceAfterWorkHour"
                                                    defaultValue={data.getPhotographyCompany.planningMaxDistanceAfterWorkHour}
                                                    onBlur={(e) => updateCompanyData('planningMaxDistanceAfterWorkHour', e.currentTarget.value)}
                                                />
                                            </GridItem>
                                        </Grid>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </Flex>
                    </CardBody>
                </Card>


                <Card p="16px" my={{ sm: "0px", xl: "0px" }} >
                    <CardBody flexDirection="column" width={'100%'} padding={'40px'} >
                        <Flex flexDirection="column" style={{ width: "100%" }}>
                            <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">
                                <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                    Nedenfor kan du aktivere de kunder, som skal benytte automatisk booking.
                                </Text>
                            </Flex>
                        </Flex>
                        <div className="CustomersContainer">
                            <div className="CustomerContainerHeader">
                                <div className="CustomerContainerHeaderName">Kunde</div>
                                <div className="CustomerContainerHeaderEnabled">
                                    <span>Aktiveret</span>
                                    <Tooltip hasArrow label={'Markere boksen ud for kunden for at aktivere automatisk planlægning for kunden.'} aria-label='A tooltip' placement='bottom'>
                                        <span>
                                            <FaInfoCircle style={{ color: '#000' }} />
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            {data.getCustomers.map((customer) => (
                                <div className="CustomerContainer" key={customer.id}>
                                    <div className="CustomerName">{customer.name}</div>
                                    <div className="CustomerEnabled">
                                        <Checkbox defaultChecked={customer.autobookingEnabled} onChange={(e) => handleCustomerEnabledChange(e, customer.id)} className="CustomerCheckBox" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </Flex>
        </div>
    );
}

export default Magnamulti_Autobooking;
