// Chakra imports

//https://docs.dndkit.com/presets/sortable
import Resizer from "react-image-file-resizer";
import {
  Flex, Text, useColorModeValue, useToast,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Icon,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Button, useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from "@chakra-ui/react";
//import { useAuth } from "./../../../../auth-context/auth.context";
import { FaPlus, FaRegCheckSquare, FaTrash, FaCloudDownloadAlt, FaCloudUploadAlt, FaMinus } from "react-icons/fa";
import {
  DndContext,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  TouchSensor,
  closestCenter,
  MouseSensor
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy
} from "@dnd-kit/sortable"

// Custom components
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import CardHeader from "../../../../components/Card/CardHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import checkmark from "./../../../../assets/img/checkmark.png";
import OrderMediaListSortableItem from "./orderMediaListSortableItem";
import mwLogo from "./../../../../assets/svg/Mindworking-logo.svg";
import { MdPhotoCameraBack } from "react-icons/md";
/*
const zipMediaFilesGql = gql`
  mutation zipMedia($ids: [ID!]) {
    zipMedia(ids: $ids)
  }
`;
*/

const deleteMediaGql = gql`
  mutation deleteMedia($ids: [ID!]) {
    deleteMedia(ids: $ids)
  }
`;

const uploadMediaToExternalSystemGql = gql`
  mutation uploadMediaToExternalSystem($ids: [ID!], $system: ExternalSystemType!) {
    uploadMediaToExternalSystem(ids: $ids, system: $system)
  }
`;

const updateMediaGql = gql`
  mutation updateMedia($input: UpdateMediaInput!) {
    updateMedia(input: $input){
      id
    }
  }
`;

const updateMediaSortingGql = gql`
  mutation updateMediaSorting($input: [UpdateMediaSortingInput]!) {
    updateMediaSorting(input: $input)
  }
`;

const uploadMediaGql = gql`
  mutation uploadMedia($input: UploadMediaInput!) {
    uploadMedia(input: $input) {
      id
    }
  }
`;

const GET_ORDER_MEDIA = gql`
  query order($id: ID!) {
    getPhotographyCompany {
      id
      integrations {
          id
          integrationType
      }
    }
    getOrder(id: $id) {
      id
      orderType
      customer {
        id
        leadhubEnabled
        mwSystemName
        cbShopId
      }
      medias {
        id
        heightInPx
        widthInPx
        filename
        fileSizeInKB
        orientation
        thumpnailUrl
        mimeType
        description
        mediaType
        mindworkingStatus
        leadHubStatus
        cbStatus
      }
    }
  }
`;

interface IProps {
  orderId: string
}

const OrderMediaList: React.FC<IProps> = ({ orderId }) => {
  const toast = useToast();
  const [uploadMedia] = useMutation(uploadMediaGql);
  const [deleteMedia] = useMutation(deleteMediaGql);
  const [updateMediaSorting] = useMutation(updateMediaSortingGql);
  //const [zipMedia] = useMutation(zipMediaFilesGql);
  const [updateMedia] = useMutation(updateMediaGql);
  const [uploadMediaToExternalSystem] = useMutation(uploadMediaToExternalSystemGql);
  //const userData = useAuth()

  const { loading, error, data, refetch } = useQuery(
    GET_ORDER_MEDIA,
    {
      variables: { id: orderId },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  );

  const { isOpen: deleteWarningIsOpen, onOpen: deleteWarningOnOpen, onClose: deleteWarningOnClose } = useDisclosure();
  const deleteWarningCancelRef = React.useRef<HTMLButtonElement>(null);
  const [selectedMediaItemIds, setSelectedMediaItemIds] = React.useState([]);
  const [mediaUploadItems, setmediaUploadItems] = React.useState([]);
  const [mediaItems, setMediaItems] = React.useState([]);
  const [imageUrls, setImageUrls] = React.useState<any>([]);

  // for drag overlay
  //const [activeItem, setActiveItem] = React.useState<any>()

  const [showDownloading, setShowDownloading] = React.useState(false);
  const { isOpen: uploadIsOpen, onOpen: uploadOnOpen, onClose: uploadOnClose } = useDisclosure();
  //const [items, setItems] = React.useState([]);
  const textColor = useColorModeValue("gray.700", "white");

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );


  if (loading) {
    if (mediaItems.length > 0) {
      setMediaItems([]);
    }
    return <p></p>; //loading
  }
  if (error) {
    return <p>Error getting medias from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting medias from server (2)</p>;
  } else {
    if (mediaItems.length === 0 && data.getOrder.medias.length > 0) {
      setMediaItems(data.getOrder.medias);
    }
  }


  var fileList = [];

  const handleOpenUpClose = () => {
    fileList = [];
    setMediaItems([]);
    setImageUrls([]);
    setmediaUploadItems([]);
    var fileCatcher = document.getElementById("file-catcher") as HTMLFormElement
    fileCatcher.reset();

    var fileInput = document.getElementById("file-input") as HTMLInputElement;
    fileInput.style.display = "initial";

    var fileListDisplay = document.getElementById("file-list-display") as HTMLInputElement;
    fileListDisplay.style.display = "none";
    uploadOnClose();
  }

  async function getImageDimensions(file) {
    let img = new Image();
    var objectUrl = URL.createObjectURL(file);
    img.src = objectUrl;
    await img.decode();
    let width = img.width;
    let height = img.height;
    URL.revokeObjectURL(objectUrl);
    return {
      width,
      height,
    }
  }

  const handleOpenUpload = () => {
    setSelectedMediaItemIds([]);
    setmediaUploadItems([]);

    uploadOnOpen();
    setTimeout(() => {
      var fileCatcher = document.getElementById("file-catcher");
      var fileInput = document.getElementById("file-input") as HTMLInputElement;
      var fileListDisplay = document.getElementById("file-list-display");

      fileCatcher.addEventListener("submit", function (evnt) {
        console.log('submit');
        evnt.preventDefault();
        uploadFiles();

      });

      fileInput.addEventListener("change", async function () {
        fileList = [];
        let fileTypeError = false;
        for (var i = 0; i < fileInput.files.length; i++) {
          let file = fileInput.files[i];
          let { width, height } = { width: 0, height: 0 };

          if (file.type !== "image/jpeg" && file.type !== "video/mp4") {
            fileTypeError = true;
          }

          try {
            ({ width, height } = await getImageDimensions(file))
          } catch (error) {
            console.log('error', error, 'file:', file.name)
          }

          file['isUploading'] = false;
          file['isuploaded'] = false;
          file['width'] = width;
          file['height'] = height;
          file['MwToLarge'] = width > 3000 || height > 3000;

          fileList.push(file);
        }

        if (fileTypeError) {
          toast({
            title: "En eller flere af filerne er ikke JPG eller MP4.",
            description: "Systemet understøtter ikke de valgte fil formater.",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          return;
        }

        const uploadTextContainer = document.getElementsByClassName("uploadTextContainer");
        if (uploadTextContainer.length > 0) {
          (uploadTextContainer[0] as HTMLDivElement).style.display = "none";
        }
        fileInput.style.display = "none";
        fileListDisplay.style.display = "initial";

        setmediaUploadItems(fileList)
      });
    }, 100);
  }

  let activeUploads = 0;
  const maxConcurrentUploads = 2;
  let fileQueue = []

  const uploadFiles = () => {
    console.log('fileList (number of files to upload): ', fileList.length);
    if (fileList.length === 0) {
      return;
    }

    setMediaItems([]);

    //set all files as uploading on ui
    for (let i = 0; i < fileList.length; i++) {
      fileList[i].isuploaded = false;
      fileList[i].isUploading = true;
      setmediaUploadItems([...fileList]);
    }

    //fileQueue = [...fileList]; // Copy fileList to a queue
    fileQueue = fileList.map((file, index) => ({ file, index })); // Copy fileList to a queue, storing the index with each file


    processQueue();
  }

  const processQueue = () => {

    while (activeUploads < maxConcurrentUploads && fileQueue.length > 0) {
      const { file, index } = fileQueue.shift(); // Remove the first file from the queue
      activeUploads++;

      sendFile(file, index);
    }
  }

  const sendFile = async (file, index) => {
    console.log('uploading file:', file, 'index:', index, 'activeUploads:', activeUploads);
    const fileBase64 = await toBase64(file);

    const inputData = {
      orderId: orderId,
      filename: file.name,
      mimeType: file.type,
      fileContentBase64: fileBase64,
    };

    setTimeout(() => {
      uploadMedia({ variables: { input: inputData } }).then(() => {
        console.log('file uploaded, index:', index, 'file:', file.name, 'activeUploads:', activeUploads);

        fileList[index].isuploaded = true;
        fileList[index].isUploading = false;
        setmediaUploadItems([...fileList]);

        var missingImages = document.getElementsByClassName("lds-ripple").length;
        if (missingImages === 0) {
          fileList = [];
          handleOpenUpClose();
          refetch();
        }

        activeUploads--; // Decrement active uploads count when a file is done uploading
        processQueue(); // Try to process the next file in the queue

      }).catch(() => {
        toast({
          title: "Der opstod en fejl, filen blev ikke uploadet.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });

        activeUploads--; // Decrement active uploads count when a file is done uploading
        processQueue(); // Try to process the next file in the queue
      })
    }, 100);
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleSliderChange = (value, storeValue: boolean) => {
    if (storeValue) {
      localStorage.setItem('order_media_list_zoom', value.toString());
    }
    updateZoom()
  }

  const updateZoom = () => {

    let value = parseInt(localStorage.getItem('order_media_list_zoom') || '44');

    if (document.getElementsByClassName('orderMediaListContainer').length > 0) {
      const containerWidth = (document.getElementsByClassName('orderMediaListContainer')[0] as HTMLElement).offsetWidth;
      let width = '0%';
      let height = '0%';

      if (value > -1 && value < 17) {
        width = '12.4%';
        height = containerWidth / 11.8 + 'px';
      }
      if (value > 17 && value < 33.6) {
        width = '14.5%';
        height = containerWidth / 10.2 + 'px';
      }
      if (value > 33.6 && value < 50.2) {
        width = '17.8%';
        height = containerWidth / 8.4 + 'px';
      }
      if (value > 50.2 && value < 66.8) {
        width = '23%';
        height = containerWidth / 6.5 + 'px';
      }
      if (value > 66.8 && value < 83.4) {
        width = '31%';
        height = containerWidth / 4.9 + 'px';
      }
      if (value > 83.4 && value < 101) {
        width = '47%';
        height = containerWidth / 3.2 + 'px';
      }

      const elements = Array.from(document.getElementsByClassName('mediaItem'))
      elements.forEach((element: HTMLInputElement) => {
        element.style.width = width;
        //element.style.height = height;
      })

      const thumbnailContainerElements = Array.from(document.getElementsByClassName('mediaItemThumbnailContainer'))
      thumbnailContainerElements.forEach((element: HTMLInputElement) => {
        //element.style.width = width;
        element.style.height = height;
      })

      const thumbnailVideoPlayIconContainers = Array.from(document.getElementsByClassName('orderMediaListVideoPlayIconContainer'))
      thumbnailVideoPlayIconContainers.forEach((element: HTMLInputElement) => {
        //element.style.width = width;
        element.style.height = height;
      })
    }
  }

  // triggered when dragging starts
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    console.log('handleDragStart - active:', active)
  }

  // triggered when dragging ends
  const handleDragEnd = (event: DragEndEvent) => {
    console.log('handleDragEnd')
    const { active, over } = event
    if (!over) return

    const activeItem = mediaItems.find((item) => item.id === active.id)
    const overItem = mediaItems.find((item) => item.id === over.id)

    if (!activeItem || !overItem) {
      return
    }

    const activeIndex = mediaItems.findIndex((item) => item.id === active.id)
    const overIndex = mediaItems.findIndex((item) => item.id === over.id)

    if (activeIndex !== overIndex) {
      const sortedItems = arrayMove<any>(mediaItems, activeIndex, overIndex)
      console.log('sortedItems:', sortedItems)

      const selectedMediaItemIds = sortedItems.map((item, index) => {
        return { id: item.id, sorting: index }
      })

      setMediaItems(sortedItems)
      updateMediaSorting({ variables: { input: selectedMediaItemIds } }).then(() => {
        //refetch();
      }).catch(() => {
        toast({
          title: "Der opstod en fejl.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      })
    }
    //setActiveItem(undefined)
  }

  const handleDragCancel = () => {
    //setActiveItem(undefined)
  }

  const handleUploadToExternalSystemClicked = (system: string) => {
    if (selectedMediaItemIds) {

      uploadMediaToExternalSystem({ variables: { ids: selectedMediaItemIds, system: system } }).then(() => {
        if (system === 'LEADHUB') {
          toast({
            title: "Filerne bliver nu klar gjort til upload til Leadhub. De vil være klar i Leadhub om ca. 20 min.",
            description: "",
            status: "success",
            duration: 10000,
            isClosable: true,
          });
        }
        if (system === 'MINDWORKING') {
          toast({
            title: "Filerne bliver nu klar gjort til upload til Mindworking. De vil være klar i Mindworking om ca. 20 min.",
            description: "",
            status: "success",
            duration: 10000,
            isClosable: true,
          });
        }

        if (system === 'CB') {
          toast({
            title: "Filerne kan nu ses i C&B.",
            description: "",
            status: "success",
            duration: 10000,
            isClosable: true,
          });
        }

        refetch();
      }).catch(() => {
        toast({
          title: "Der opstod en fejl.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      })
    }
  }

  /*
  const handleMwUploadClicked = () => {
    if (selectedMediaItemIds) {
      for (let i = 0; i < selectedMediaItemIds.length; i++) {

        const inputData = {
          id: selectedMediaItemIds[i],
          mindworkingStatus: "WAITING_TO_BE_UPLOADED"
        }

        updateMedia({ variables: { input: inputData } })
      }

      toast({
        title: "Filerne bliver nu klar gjort til upload til Mindworking. De vil være klar i Mindworking om ca. 20 min.",
        description: "",
        status: "success",
        duration: 10000,
        isClosable: true,
      });
    }
  }
    */

  const handleZipFilesClicked = () => {
    if (selectedMediaItemIds) {
      setShowDownloading(true);

      let url = process.env.REACT_APP_EXTERNAL_API_URL + '/media/zipMedia?ids=' + selectedMediaItemIds;
      var link = document.createElement('a');
      link.href = url;
      link.download = 'media.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowDownloading(false);
    }
  }

  const deleteMediasConfirmed = () => {
    if (selectedMediaItemIds) {
      deleteMedia({ variables: { ids: selectedMediaItemIds } }).then(() => {
        deleteWarningOnClose();
        setSelectedMediaItemIds([])
        refetch();
      }).catch(() => {
        toast({
          title: "Der opstod en fejl.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      })
    }
  }

  const handleDescriptionChanged = (id, description) => {
    const mediaDescriptionItem = mediaItems.find((media) => media.id === id);

    mediaDescriptionItem.description = description;
    setMediaItems([...mediaItems]);

    const inputData = {
      id: id,
      description: description
    }


    updateMedia({ variables: { input: inputData } }).then(() => {
      toast({
        title: "Ændringen er blevet gemt.",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(() => {
      toast({
        title: "Der opstod en fejl.",
        description: "",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    })

  }

  const handleCheckBoxClicked = (e) => {
    e.stopPropagation();
    if (e.target.checked) {
      setSelectedMediaItemIds([...selectedMediaItemIds, e.target.value]);
    } else {
      setSelectedMediaItemIds(selectedMediaItemIds.filter((id) => id !== e.target.value));
    }
  }

  const handleDeleteFileClicked = (id: string | null) => {
    deleteWarningOnOpen();
    if (id) {
      setSelectedMediaItemIds([id]);
    }
  }

  const handleSelectAll = () => {
    if (selectedMediaItemIds.length === mediaItems.length) {
      setSelectedMediaItemIds([]);
    } else {
      setSelectedMediaItemIds(mediaItems.map((media) => media.id));
    }
  }

  const isSelected = (id) => {
    return selectedMediaItemIds.includes(id);
  }

  const zoomValue = parseInt(localStorage.getItem('order_media_list_zoom') || '44');

  if (localStorage.getItem('order_media_list_zoom') === null) {
    console.log('setting zoomValue to 44')

    handleSliderChange(44, true);
  }

  setTimeout(() => {
    updateZoom();
  }, 10);

  const isUploading = mediaUploadItems.some((item) => item.isUploading);

  /*
  
    const loadImage = (file: Blob) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          typeof reader.result === "string" ? resolve(reader.result) : reject(reader.result);
        reader.onerror = error => reject(error);
      });
  */


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });


  const FilePreview: React.FC<{ file: File }> = ({ file }) => {

    //const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);


    React.useEffect(() => {
      const imageUrl = imageUrls.find((image) => image.name === file.name);
      if (imageUrl) {
        return;
      }



      resizeFile(file).then((imageUrl) => {
        setImageUrls((prev) => [...prev, { name: file.name, url: imageUrl }])
      });
    }, [file]);

    const imageUrl = imageUrls.find((image) => image.name === file.name);

    return (
      <>
        {imageUrl && <img id="img" className="uploadItem" src={imageUrl.url} alt={file.name} />}
      </>
    );
  };

  const showMwUploadWarning = () => {
    let showWarning = false;
    mediaUploadItems.forEach((media) => {
      if (media.MwToLarge) {
        showWarning = true;
      }
    })
    return showWarning;
  }

  const isIntegrationActive = (integrationType: string) => {
    if (integrationType !== 'MINDWORKING') {
      if (data.getOrder.orderType !== 'ESTATECASE') {
        return false
      }

      return data.getPhotographyCompany.integrations.find((integration: any) => integration.integrationType === integrationType) !== undefined;
    }

    if (integrationType === 'MINDWORKING') {
      //is the integration active
      const isActive = data.getPhotographyCompany.integrations.find((integration: any) => integration.integrationType === integrationType) !== undefined;

      if (isActive) {
        console.log('process.env.REACT_APP_USE_MINDWORKINGV2:', process.env.REACT_APP_USE_MINDWORKINGV2)
        if (process.env.REACT_APP_USE_MINDWORKINGV2 === 'true') {
          //Mindworking V2
          if (data.getOrder.orderType === 'ESTATECASE' || data.getOrder.orderType === 'SHOP' || data.getOrder.orderType === 'EMPLOYEE') {
            return true;
          } else {
            return false;
          }
        } else {
          //Mindworking V1
          if (data.getOrder.orderType !== 'ESTATECASE') {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  }

  const handleCopyToClipBoard = () => {
    // find the text to copy
    let textToCopy = "";
    
      textToCopy = process.env.REACT_APP_CUSTOMER_UI_URL + "/media/" + data.getOrder.id;
      // Copy the text inside the text field
      navigator.clipboard.writeText(textToCopy);
      toast({
        title: "Linket er kopieret til udklipsholderen.",
        description: "",
        status: "success",
        duration: 5000,
        isClosable: true,
    })
  
  }

  return (
    <>
      <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
        <CardHeader p="12px 5px" mb="12px" style={{ justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'nowrap', flexDirection: 'column' }}>
          <Text fontSize="lg" color={textColor} fontWeight="bold"  >
            Media
          </Text>
          <br />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            {selectedMediaItemIds.length > 0 && (
              <>
                {(isIntegrationActive('LEADHUB') && data.getOrder.customer.leadhubEnabled) && (
                  <div style={{ width: '160px', display: 'flex', cursor: 'pointer' }} onClick={() => handleUploadToExternalSystemClicked('LEADHUB')}>
                    <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAANlBMVEX////////9/f/////r7PxZaegaOuPJzfbX2vgwSeS4vfQ4TuWKlO5qeOqbo/CmrfFEV+Z5hewsICHSAAAAA3RSTlMDufvnWETiAAAAtUlEQVR4AXTSCwoDIQxFUcd/fNqJ+99sNUwZSGMAEY7CRXTu8sEcfy0Lx7mcP6N37z7+6YsplyNWonjEBgpH7BgSYWLCZ613vi0k7B6gGRilJxKqgUV6Ksgb+EGSZLaCJqYcmaH0olBi5cgAZ40syMwAU9TIKZaxrO00hUPuMMrqThorATQYq5imxuBrDQ1jY9co09EXYpo4cL+vpPD5DMTDwrgttGziMz788Dt8SRNvosabHQBywQ1KsNl1KQAAAABJRU5ErkJggg=='} height={18} width={18} alt='Uploaded til LeadHub' className="mwUploadButtonLogo" />
                    <div className="orderMediaListMenuText">Upload til LeadHub</div>
                  </div>
                )}


                {(isIntegrationActive('MINDWORKING') && data.getOrder.customer.mwSystemName.length > 2) && (
                  <div style={{ width: '160px', display: 'flex', cursor: 'pointer' }} onClick={() => handleUploadToExternalSystemClicked('MINDWORKING')}>
                    <img src={mwLogo} height={18} width={18} alt='Uploaded til Mindworking' className="mwUploadButtonLogo" />
                    <div className="orderMediaListMenuText">Upload til Mindworking</div>
                  </div>
                )}

                {(isIntegrationActive('CB') && data.getOrder.customer.cbShopId.length > 2) && (
                  <div style={{ width: '115px', display: 'flex', cursor: 'pointer' }} onClick={() => handleUploadToExternalSystemClicked('CB')}>
                    <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAS1BMVEVHcEzY0svb1c7c1s/b1s7c1s/b1c/c1s/b1c7a1M3X1M3a1M3b1c7l3dXNzca7vLiLlJUAGjVyf4QHM0ZYa3MlSVdBWmSmqai1zb9lKzZFAAAADXRSTlMAJ2umvtXl/3RX/WB+GIHG1QAAARFJREFUeAGFklUCwzAMQ8PKvIbK9z/pXBwV3kdICtkWH0iljbVGKymOUA47zv/JD8IX9PjWNf4wn7rFAfZGB9zh+Qe3PHDKczYQzpn/jwsqNizxCTHFsLaIMdL+TrnouZRSr23ktslsZOR2Q2lRl9i0oe3YkGPXLAa//pFK5uPR9xSZktH1i0ELsxuAkKbDm9hwN4Q1nPZtIDQt5ZL4BGoLvgyYX5iWNrKbH7Qalit4oSl96LntmliYdrtCY3GkYeBuyAmJErPo0Ns3iQIiCNwukxW/BgrjOMaRibSNMCPXUO/QVwfiXPibZN2k+6ZglJgxNyV3X7TsuNEPbzHiiycdve+LymGHKnGE9Noy2kvx5gU9nhys476LsgAAAABJRU5ErkJggg=='} height={18} width={18} alt='Uploaded til C&B' className="cbUploadButtonLogo" />
                    <div className="orderMediaListMenuText">Upload til C&B</div>
                  </div>
                )}

                <div style={{ width: '70px', display: 'flex', cursor: 'pointer' }} onClick={() => handleZipFilesClicked()}>
                  <Icon fontSize={18} as={FaCloudDownloadAlt} me="6px" />
                  <div className="orderMediaListMenuText">Hent</div>
                </div>
                <div style={{ width: '65px', display: 'flex', cursor: 'pointer' }} onClick={() => handleDeleteFileClicked(null)}>
                  <Icon as={FaTrash} me="6px" />
                  <div className="orderMediaListMenuText">Slet</div>
                </div>
              </>
            )}



            <div style={{ width: '140px', display: 'flex', cursor: 'pointer' }} onClick={() => handleSelectAll()}>
              <Icon as={FaRegCheckSquare} me="6px" />
              <div className="orderMediaListMenuText">Vælg/Fravælg alle</div>
            </div>

            {mediaItems.length > 0 && (
            <div style={{ width: '190px', display: 'flex', cursor: 'pointer' }} onClick={() => handleCopyToClipBoard()}>
              
              <MdPhotoCameraBack />
              <div className="orderMediaListMenuText" style={{paddingLeft:'5px'}}>Kopier link til billedfremviser</div>
            </div>
            )}


            <div style={{ width: '180px', display: 'flex', cursor: 'pointer' }} onClick={() => handleOpenUpload()}>
              <Icon fontSize={18} as={FaCloudUploadAlt} me="8px" />
              <div className="orderMediaListMenuText">Upload færdigt materiale</div>
            </div>
            <div style={{ width: '200px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
              <Icon as={FaMinus} me="6px" />
              <Slider aria-label='slider-ex-1' defaultValue={zoomValue} onChange={(e) => { handleSliderChange(e, true) }} style={{ display: 'inline' }}>
                <SliderTrack bg='#000000'>
                  <SliderFilledTrack bg='#000000' />
                </SliderTrack>
                <SliderThumb bg='#bfbfbf' style={{ boxShadow: 'none' }} />
              </Slider>
              <Icon as={FaPlus} me="6px" />
            </div>
          </div>
        </CardHeader>
        <CardBody px="5px" height={"100%"} className='orderMediaListContainer'>
          <Flex direction="column" style={{ width: "100%", height: "100%" }}>
            <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"} direction="row" wrap="wrap">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext
                  items={mediaItems}
                  strategy={rectSortingStrategy}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap'
                    }}
                  >
                    {mediaItems.map((media) =>
                    (
                      <OrderMediaListSortableItem key={media.id} media={media} handleCheckBoxClicked={handleCheckBoxClicked} handleDescriptionChanged={handleDescriptionChanged} isSelected={isSelected(media.id)} />
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
            </Flex>
          </Flex>
        </CardBody>
      </Card >

      {showDownloading && (
        <div className="downloadOverlay">
          <div className="downloadOverlayText"> Henter filerne øjeblik...</div>
        </div>
      )}

      <Modal onClose={handleOpenUpClose} size="xl" isOpen={uploadIsOpen} >
        <ModalOverlay />
        <ModalContent maxH="800px" maxW="1000px">
          <ModalHeader>Upload media</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction='column' style={{ width: '100%' }}>
              <Flex align='center' mb='18px'>
                <form id='file-catcher' style={{ width: '100%' }}>
                  <div className="fileuploadContainer" style={{ width: '100%' }}>
                    <input id='file-input' type='file' multiple />
                    <div className="uploadTextContainer">
                      <i className="material-icons uploadIcon">cloud_upload</i>
                      <p className="uploadText">Vælg en eller flere filer eller træk dem herhen.</p>
                    </div>
                    <div id='file-list-display' style={{ display: 'none' }}>
                      {mediaUploadItems.map((file, index) => {
                        if (file.type === "video/mp4") {
                          return (
                            <div key={index} className="uploadItemContainer">
                              <video id="img" width="140" height="140" className="uploadItem" controls>
                                <source src={window.URL.createObjectURL(file)} type="video/mp4"></source>
                              </video>
                              <div className="uploadItemFilename">{file.name}</div>
                              {file.isUploading && (
                                <div className="lds-ripple" style={{ position: 'absolute', top: '30px', left: '30px' }}>
                                  <div></div>
                                  <div></div>
                                </div>
                              )}
                              {file.isuploaded && (
                                <img className="greenCheckmark" src={checkmark} alt="Video uploaded" />
                              )}
                            </div>
                          )
                        } else {
                          return (
                            <div key={index} className="uploadItemContainer">
                              <FilePreview file={file} />
                              {/*
                              <img id="img" className="uploadItem" src={getImageUrl(file)} alt="Media" />
                            */}
                              <div className="uploadItemFilename">{file.name}</div>
                              {file.isUploading && (
                                <div className="lds-ripple" style={{ position: 'absolute', top: '30px', left: '30px' }}>
                                  <div></div>
                                  <div></div>
                                </div>
                              )}
                              {file.isuploaded && (
                                <img className="greenCheckmark" src={checkmark} alt="Media uploaded" />
                              )}
                            </div>
                          )
                        }
                      })}

                    </div>

                  </div>
                  <button id="submitbtn" type='submit' style={{ display: 'none' }}>
                    Submit
                  </button>
                </form>
              </Flex>
            </Flex>

          </ModalBody>
          <ModalFooter>
            {!isUploading && (
              <>
                {showMwUploadWarning() && (
                  <>
                    <div style={{ color: 'red', fontSize: '12px' }}>Et eller flere af billederne overstiger dimensionerne 3000 x 3000.<br />Hvis de uploades til Mindworking, vil Mindworking nedskalere dem, hvilket kan resultere i tab af kvalitet.</div>
                    <div style={{ width: '23%' }}>&nbsp;</div>
                  </>
                )}
                <Button onClick={handleOpenUpClose}>Fortryd</Button>
                {mediaUploadItems.length > 0 && (
                  <Button colorScheme='green' onClick={() => { document.getElementById('submitbtn').click() }} ml={3}>Upload</Button>
                )}
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>


      <AlertDialog isOpen={deleteWarningIsOpen} leastDestructiveRef={deleteWarningCancelRef} onClose={deleteWarningOnClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet Media
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={deleteWarningOnClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteMediasConfirmed} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default OrderMediaList;