// Chakra imports
import {
    Flex,
    useColorModeValue,
    useToast,
    Tooltip,
    Button,
    Text,
    Select,
    Image
} from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import Header from "../../../../../components/Headers/Header";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { useQuery, useMutation, gql } from "@apollo/client";
import dinero_logo from "./../../../../../assets/img/dinero_logo.jpg";
import './../integrations.css';
import { FaInfoCircle } from "react-icons/fa";
import React from "react";

const CUSTOMERS_GQL = gql`
query customers {
    getCustomers {
        id
        name
        dineroCustomerId
    }
    getPhotographyCompany {
        id
        integration(integrationType: DINERO) {
            id
            organizationId
            accessLink
            haveAccess
            organizations {
                id
                name
            }
            customers {
                id
                name
            }
        }
    }
}`

const UPDATE_CUSTOMER_GQL = gql`
mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input:$input){
        id
    }
}`

const UPDATE_INTEGRATION_GQL = gql`
mutation updateIntegration($input: UpdateIntegrationInput!) {
    updateIntegration(input:$input){
        id
    }
}`

function Dinero() {
    const toast = useToast()
    const organisationRef = React.createRef<HTMLSelectElement>();

    //Colors
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const [updateCustomer] = useMutation(
        UPDATE_CUSTOMER_GQL,
        {
            //refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const [updateIntegration] = useMutation(
        UPDATE_INTEGRATION_GQL,
        {
            refetchQueries: [CUSTOMERS_GQL]
        }
    )

    const { loading, error, data } = useQuery(CUSTOMERS_GQL, {
        variables: {},
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    if (loading) return (<p></p>); //loading

    if (error) {
        return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
    }

    if (!data) {
        return (<p>Error getting subcontractor from server (2)</p>);
    }

    const handleDineroOrganizationSelected = () => {
        const organizationId = organisationRef.current.value;

        updateIntegration({
            variables: {
                input: {
                    id: data.getPhotographyCompany.integration.id,
                    organizationId: organizationId
                }
            }
        });
        //console.log(organizationId);
    }

    const handleDineroCustomerChange = (e, customerId) => {
        const value = e.target.value;
        updateCustomer({
            variables: {
                input: {
                    id: customerId,
                    dineroCustomerId: value
                }
            }
        }).then(() => {
            toast({
                title: "Kunden er opdateret",
                description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch((error) => {
            toast({
                title: "Fejl",
                description: "Der skete en fejl ved opdatering af Kunden.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    return (
        <div className="DineroSettings">
            <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
                <Header
                    backgroundHeader={ProfileBgImage}
                    backgroundProfile={bgProfile}
                    name={"Indstillinger - Dinero"}
                    description={'Opsætning af Dinero'}
                    showBackButton={true}
                />

                <Card p="16px" my={{ sm: "0px", xl: "24px" }}>
                    <CardHeader p="12px 5px" justifyContent="space-between" >
                        <Flex justifyContent="center" align="center" style={{ width: "100%" }}>
                            <Image
                                src={dinero_logo}
                                alt='Dinero'
                                maxWidth={'270px'}
                            />
                        </Flex>
                    </CardHeader>
                </Card>

                {!data.getPhotographyCompany.integration.haveAccess && (
                    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>

                        <CardBody width={'100%'}>
                            <Flex direction="column" style={{ width: "100%" }}>
                                <Flex align="center" mb="28px" mt="28px" style={{ width: "100%" }}>
                                    <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                        For at kunne bruge Dinero, skal du forbinde til Dinero.<br />Klik på linket nedenfor for at forbinde.
                                    </Text>
                                </Flex>

                                <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                    <Button mt="10px" onClick={() => window.location = data.getPhotographyCompany.integration.accessLink}>Forbind til Dinero</Button>
                                </Flex>
                            </Flex>
                        </CardBody>
                    </Card>
                )}

                {(data.getPhotographyCompany.integration.haveAccess &&
                    data.getPhotographyCompany.integration.organizationId === "" &&
                    !data.getPhotographyCompany.integration.organizations) && (
                        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
         
                            <CardBody width={'100%'}>
                                <Flex direction="column" style={{ width: "100%" }}>
                                    <Flex align="center" mb="28px" mt="28px" style={{ width: "100%" }}>
                                        <Text fontSize="md" color={'red.500'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Der er opstået en fejl, prøv at forbinde til Dinero igen.
                                        </Text>
                                    </Flex>

                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <Button mt="10px" onClick={() => window.location = data.getPhotographyCompany.integration.accessLink}>Forbind til Dinero</Button>
                                    </Flex>
                                </Flex>
                            </CardBody>
                        </Card>
                    )}

                {(data.getPhotographyCompany.integration.haveAccess &&
                    data.getPhotographyCompany.integration.organizationId === "" &&
                    data.getPhotographyCompany.integration.organizations) && (
                        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
                            <CardBody width={'100%'}>

                                <Flex direction="column" style={{ width: "100%" }}>
                                    <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">

                                        <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Du er nu forbundet med Dinero, vælg den virksomhed i Dinero som du gerne vil benytte.
                                        </Text>

                                        <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Bemærk du skal have et dinero pro abonnement for at kunne benytte denne integration.
                                        </Text>

                                    </Flex>
                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <span style={{ width: "400px" }}>
                                            <Select ref={organisationRef} >
                                                {data.getPhotographyCompany.integration.organizations.map((organization) => (
                                                    <option key={organization.id} value={organization.id}>{organization.name}</option>
                                                ))}
                                            </Select>
                                        </span>
                                    </Flex>

                                    <Flex justifyContent="center" align="center" mb="18px" style={{ width: "100%" }}>
                                        <Button width={"200px"} mt="10px" onClick={handleDineroOrganizationSelected} >OK</Button>
                                    </Flex>
                                </Flex>
                            </CardBody>
                        </Card>
                    )}

                {data.getPhotographyCompany.integration.haveAccess && data.getPhotographyCompany.integration.organizationId !== "" && (
                    <>


                        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>

                            <CardBody flexDirection="column" width={'100%'} padding={'40px'}>
                                <Flex flexDirection="column" style={{ width: "100%" }}>
                                    <Flex align="center" mb="28px" mt="0px" style={{ width: "100%" }} flexDirection="column">

                                        <Text fontSize="md" color={'gray.700'} fontWeight="bold" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Du er nu forbundet med Dinero!
                                            </Text>
                                            <Text fontSize="sm" color={'gray.700'} fontWeight="normal" me="10px" whiteSpace={"nowrap"} align={'center'} style={{ width: "100%" }}>
                                            Nedenfor skal du matche dine kunder i Dinero med dine kunder her i Magna Multi.
                                        </Text>

                                        
                                    </Flex>
                                </Flex>
                                <div className="CustomersContainer">
                                    <div className="CustomerContainerHeader">
                                        <div className="CustomerContainerHeaderName">Magna Multi - Kunde</div>
                                        <div className="CustomerContainerHeaderSelected">
                                            <span>Dinero - Kunde</span>
                        
                                        </div>
                                    </div>
                                    {data.getCustomers.map((customer) => (
                                        <div className="CustomerContainer" key={customer.id}>
                                            <div className="CustomerName">{customer.name}</div>
                                            <div className="CustomerSelected">
                                                <Select defaultValue={customer.dineroCustomerId} onChange={(e) => handleDineroCustomerChange(e, customer.id)} >
                                                    <option value={""}>-</option>
                                                    {data.getPhotographyCompany.integration.customers.map((dineroCustomer) => (
                                                        <option key={dineroCustomer.id} value={dineroCustomer.id}>{dineroCustomer.name}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </>
                )}
            </Flex>
        </div>
    );
}

export default Dinero;
