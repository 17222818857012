
import { gql, useMutation } from "@apollo/client";
import {
    Flex,
    Text,
    useColorModeValue,
    Grid,
    GridItem,
    Select,
    Switch
} from "@chakra-ui/react";
import { useState } from "react";
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

const UPDATE_WORKINGHOUR = gql`
  mutation updateWorkingHour($input: UpdateWorkingHourInput!) {
    updateWorkingHour(input: $input) {
      id
    }
  }
`;

interface IProps {
    active: boolean,
    workingHours: any,
    userId: string | null
}

const WorkingHours: React.FC<IProps> = ({ workingHours, active, userId }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const [isActive, setIsActive] = useState(active);
    const [tmpWorkingHours, setTmpWorkingHours] = useState(workingHours);
    const [updateUserInDB] = useMutation(UPDATE_USER);
    const [updateWorkingHourInDB] = useMutation(UPDATE_WORKINGHOUR);

    const updateUser = (field, value) => {
        updateUserInDB({
            variables: {
                input: {
                    id: userId,
                    [field]: value
                }
            }
        }).then(() => {
            setIsActive(value);
        });
    }

    const updateWorkingHour = (day, field, value) => {
        updateWorkingHourInDB({
            variables: {
                input: {
                    id: tmpWorkingHours[day].id,
                    [field]: value
                }
            }
        }).then(() => {
            const newWorkingHours = [...tmpWorkingHours];
            newWorkingHours[day][field] = value;
            setTmpWorkingHours(newWorkingHours);
        });
    }

    const generateTimeArray = (startTime = "00:30", endTime = "24:00", intervalMinutes = 30) => {
        const times = [];
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        let currentHour = startHour;
        let currentMinute = startMinute;

        while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
            // Format time as HH:mm
            const formattedTime = `${String(currentHour).padStart(2, "0")}:${String(currentMinute).padStart(2, "0")}`;
            times.push(formattedTime);

            // Increment time by interval
            currentMinute += intervalMinutes;
            if (currentMinute >= 60) {
                currentHour++;
                currentMinute -= 60;
            }
        }

        return times;
    }

    const getNameOfDay = (day) => {
        switch (day) {
            case 0:
                return 'Søndag';
            case 1:
                return 'Mandag';
            case 2:
                return 'Tirsdag';
            case 3:
                return 'Onsdag';
            case 4:
                return 'Torsdag';
            case 5:
                return 'Fredag';
            case 6:
                return 'Lørdag';
            default:
                return '';
        }
    }

    return (
        <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
                {userId ? (
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Arbejdestid
                    </Text>
                ) : (
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Åbningstider
                    </Text>
                )}
            </CardHeader>
            <CardBody px="5px">
                <Flex direction="column" style={{ width: "100%" }}>
                    {userId && (
                        <Flex align="center" mb="18px" >
                            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                <GridItem colSpan={6} rowSpan={1} display={'flex'} alignItems={"center"} justifyContent={"space-between"}>
                                    <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"}>
                                        Aktivere arbejdstid:{" "}
                                    </Text>
                                    <Switch id='ActivateWorkingHours' defaultChecked={isActive} onChange={(e) => updateUser('useWorkingHours', e.currentTarget.checked)} />
                                </GridItem>
                            </Grid>
                        </Flex>
                    )}

                    {(!userId || isActive) && (
                        <Flex align="center" mb="18px" style={{ borderTop: '1px solid #E2E8F0', paddingTop: '20px' }}>
                            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                                {[1, 2, 3, 4, 5, 6, 0].map((day) => (
                                    <GridItem colSpan={6} rowSpan={1} display={'flex'} alignItems={"center"} justifyContent={"space-evenly"} gap={'10px'} style={{ height: '40px' }}>
                                        <Switch id='monday_active' defaultChecked={!tmpWorkingHours[day].is_closed} onChange={(e) => updateWorkingHour(day, 'is_closed', !e.currentTarget.checked)} />
                                        <div style={{ width: '65px' }}>
                                            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"}>
                                                {getNameOfDay(day)}: {" "}
                                            </Text>
                                        </div>
                                        {tmpWorkingHours[day].is_closed ? (
                                            <>
                                                <div style={{
                                                    width: '100%', display: 'flex',
                                                    justifyContent: 'center',
                                                    background: 'var(--chakra-colors-gray-200)',
                                                    borderRadius: '5px',
                                                    padding: '5px'

                                                }} >
                                                    {userId ? <b>Fri</b> : <b>Lukket</b>}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Select variant="flushed" name="monday" defaultValue={tmpWorkingHours[day].start_time.split(':')[0] + ':' + tmpWorkingHours[day].start_time.split(':')[1]} onBlur={(e) => updateWorkingHour(day, 'start_time', e.currentTarget.value)}>
                                                    {generateTimeArray().map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <Select variant="flushed" name="monday" defaultValue={tmpWorkingHours[day].end_time.split(':')[0] + ':' + tmpWorkingHours[day].end_time.split(':')[1]} onBlur={(e) => updateWorkingHour(day, 'end_time', e.currentTarget.value)}>
                                                    {generateTimeArray().map((time) => (
                                                        <option key={time} value={time}>
                                                            {time}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    </GridItem>
                                ))}
                            </Grid>
                        </Flex>
                    )}
                </Flex>
            </CardBody >
        </Card >
    )
};

export default WorkingHours;
