// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast, Grid, GridItem } from "@chakra-ui/react";

// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";

const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;


const OrderAddress = ({ data, dataChanged }) => {
  ///const toast = useToast();
  // Chakra color mode
  const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const textColor = useColorModeValue("gray.700", "white");

  const toast = useToast();


  const updateData = (orderId, field, data) => {
    let inputData = {};
    inputData.id = orderId;
    inputData[field] = parseInt(data);

    updateOrderInfo({
      variables: { input: inputData },
    })
      .then(() => {
        dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere ordren.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };



  const addressLink = 'https://maps.google.com/?q=' + encodeURIComponent(data.estateAddress + ", " + data.estateZipCode + " " + data.estateCity)
  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Adresse information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="flex-start" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                  Adresse
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"120px"}>
                  {data.estateAddress}<br />
                  {data.estateZipCode} {data.estateCity}
                </Text>
                {/*
                <a href={addressLink} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Interface / External_Link">
                      <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </svg>
                </a>
                */}
              </GridItem>
            </Grid>
          </Flex>

          <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "repeat(6, 1fr)", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Google kort
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                  <a href={addressLink} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Interface / External_Link">
                        <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </a>
                </GridItem>
              </Grid>
            </Flex>

          {data.cadastreMapUrl.length > 0 && (
            <Flex align="flex-start" mb="18px">
              <Grid templateColumns={{ sm: "repeat(6, 1fr)", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                  <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                    Matrikkel kort
                  </Text>
                </GridItem>
                <GridItem colSpan={5} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                  <a href={data.cadastreMapUrl} target="_blank" referrerPolicy="no-referrer" rel="noreferrer">
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Interface / External_Link">
                        <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                    </svg>
                  </a>
                </GridItem>
              </Grid>
            </Flex>
          )}

          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
              Ejendomstype:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.estateType}
            </Text>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
              Antal etager:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.estateFloorCount}
            </Text>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
              Antal rum:{" "}
            </Text>
            <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
              {data.estateRoomCount}
            </Text>
          </Flex>
          <Flex align="flex-start" mb="18px" alignItems={'center'}>
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
              Bolig areal:{" "}
            </Text>
            <Input
              maxWidth={{ sm: "120px", md: "100%", xl: "100%" }}
              variant="flushed"
              type="number"
              name="estateLivingArea"
              defaultValue={data.estateLivingArea}
              onBlur={(e) => updateData(data.id, 'estateLivingArea', e.currentTarget.value)}
            />
          </Flex>
          <Flex align="flex-start" mb="18px" alignItems={'center'}>
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
              Erhvervs areal:{" "}
            </Text>
            <Input
              maxWidth={{ sm: "120px", md: "100%", xl: "100%" }}
              variant="flushed"
              type="number"
              name="estateBusinessArea"
              defaultValue={data.estateBusinessArea}
              onBlur={(e) => updateData(data.id, 'estateBusinessArea', e.currentTarget.value)}
            />

          </Flex>

        </Flex>
      </CardBody>
    </Card>
  );
};

export default OrderAddress;
