// Chakra imports
import { Flex, Text, useColorModeValue, Input, useToast, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";
import { useAuth } from "./../../../../../auth-context/auth.context";

const updatePhotographyCompanyInfoGql = gql`
  mutation UpdatePhotographyCompanyInput($input: UpdatePhotographyCompanyInput!) {
    updatePhotographyCompany(input: $input) {
      id
    }
  }
`;


const ProfileContactInfo = ({ data }) => {
  const toast = useToast();
  const userData = useAuth()
  const [updateCustomerInfo] = useMutation(updatePhotographyCompanyInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (field, value) => {
    const dataObj = {}

    dataObj['id'] = data.id;
    if (field === 'zipCodeAndCity') {
      dataObj['zipCode'] = value.match(/\d+/g)[0];
      dataObj['city'] = value.match(/[a-zA-Z]+/g)[0];
    } else {
      dataObj[field] = value;
    }

    if (field === 'name') {
      userData.user.photographyCompany.name = value;
      userData.setUser({ ...userData.user });
    }

    updateCustomerInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Kontakt information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  Firma navn:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="name"
                  defaultValue={data.name}
                  onBlur={(e) => updateData('name', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>
          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  Kontaktperson.:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="contactPersonName"
                  defaultValue={data.contactPersonName}
                  onBlur={(e) => updateData('contactPersonName', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>
          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  Vejnavn og nr.:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="address"
                  defaultValue={data.address}
                  onBlur={(e) => updateData('address', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>

          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  Post nr. og by:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="zipCodeAndCity"
                  defaultValue={data.zipCode + " " + data.city}
                  onBlur={(e) => updateData('zipCodeAndCity', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>

          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  cvr:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="cvr"
                  defaultValue={data.cvr}
                  onBlur={(e) => updateData('cvr', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>



          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  E-mail:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="email"
                  defaultValue={data.email}
                  onBlur={(e) => updateData('email', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>


          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  Telefon nr.:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  name="phoneNo"
                  defaultValue={data.phoneNo}
                  onBlur={(e) => updateData('phoneNo', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>


          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                  website url:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={5} rowSpan={1} >
                <Input
                  variant="flushed"
                  type="text"
                  width={"100%"}
                  name="websiteUrl"
                  defaultValue={data.websiteUrl}
                  onBlur={(e) => updateData('websiteUrl', e.currentTarget.value)}
                />
              </GridItem>
            </Grid>
          </Flex>


        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileContactInfo;
