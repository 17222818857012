/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import
// To be changed
// import Tables from "views/Dashboard/Tables.js";

import { HomeIcon, StatsIcon, DocumentIcon, SupportIcon, HelpIcon, MessageIcon, CustomerIcon, ReportIcon } from "./components/Icons/Icons";
import Dashboard from "./views/Dashboard/Dashboard";
import Reports from "./views/Dashboard/Reports";
import SignIn from "./views/Auth/SignIn.js";
import ForgotPassword from "./views/Auth/ForgotPassword";
import ChangePassword from "./views/Auth/ChangePassword";
import ProductTurnoverReportDetailed from "./views/Dashboard/Reports/productTurnoverReportDetailed";
import Orders from "./views/Dashboard/Orders";
import OrderInfo from "./views/Dashboard/Orders/info/index"
import OrderMedia from "./views/Dashboard/Orders/media/index"
import Customers from "./views/Dashboard/Customers/index";
import CustomerInfo from "./views/Dashboard/Customers/info/index"
import Profile from "./views/Dashboard/Settings/Profile";
import Users from "./views/Dashboard/Settings/Users";
import StandardMessages from "./views/Dashboard/Settings/StandardMessages";
import StandardMessage from "./views/Dashboard/Settings/StandardMessages/info"
import Products from "./views/Dashboard/Settings/Products/index";
import UserInfo from "./views/Dashboard/Settings/Users/info";
import ProductCategory from "./views/Dashboard/Settings/Products/productCategory";
import Product from "./views/Dashboard/Settings/Products/product";
import ExtraServiceProducts from "./views/Dashboard/Settings/ExtraServiceProducts/index";
import Calendar from "./views/Handlers/Calendar";
import BillingReport from "./views/Dashboard/Reports/billingReport";
import GlobalSubcontractorBilling from "./views/Dashboard/Reports/globalSubcontractorBilling";
import Subcontractors from "./views/Dashboard/Settings/Subcontractors";
import SubcontractorInfo from "./views/Dashboard/Settings/Subcontractors/info";
import OrderText from "./views/Dashboard/Orders/text";
import PhotographyCompanies from "./views/Dashboard/Settings/PhotographyCompanies";
import HelpCenter from "./views/Dashboard/HelpCenter";
import TextProfiles from "./views/Dashboard/Settings/TextProfiles";
import TextProfile from "./views/Dashboard/Settings/TextProfiles/TextProfile";
import Messages from "./views/Dashboard/Messages";
import Integrations from "./views/Dashboard/Settings/Integrations";
import Mindworking from "./views/Dashboard/Settings/Integrations/integration/mindworking";
import LeadHub from "./views/Dashboard/Settings/Integrations/integration/leadhub";
import CB from "./views/Dashboard/Settings/Integrations/integration/cb";
import Dinero from "./views/Dashboard/Settings/Integrations/integration/dinero";
import Economic from "./views/Dashboard/Settings/Integrations/integration/economic";
import Magnamulti_Autobooking from "./views/Dashboard/Settings/Integrations/integration/magnamulti_autobooking";

var dashRoutes = [
  {
    path: "/calendar/:photographerId/:userId",
    name: "calendar",
    rtlName: "calendar",
    icon: <DocumentIcon color="inherit" />,
    component: Calendar,
    layout: "/handlers",
    protected: false,
    show: false,
  },
  {
    path: "/calendar/:photographerId",
    name: "calendar",
    rtlName: "calendar",
    icon: <DocumentIcon color="inherit" />,
    component: Calendar,
    layout: "/handlers",
    protected: false,
    show: false,
  },
  {
    path: "/overblik",
    name: "Overblik",
    key: "overblik",
    rtlName: "Overblik",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    path: "/orders/:paramsCustomerId",
    key: "ordre",
    name: "Ordre",
    rtlName: "Ordre",
    icon: <DocumentIcon color="inherit" />,
    component: Orders,
    layout: "/admin",
    protected: true,
    show: false,
  },
  {
    path: "/orders",
    key: "ordrer",
    name: "Ordrer",
    rtlName: "Ordre",
    icon: <DocumentIcon color="inherit" />,
    component: Orders,
    layout: "/admin",
    protected: true,
    show: true,
  },
  
  {
    name: "order",
    path: "/order",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/order/:orderId/info",
        name: "Ordre info",
        key: "ordreInfo",
        rtlName: "ordreInfo",
        icon: <StatsIcon color="inherit" />,
        component: OrderInfo,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/order/:orderId/media",
        name: "Ordre media",
        key: "ordreMedia",
        rtlName: "ordreMedia",
        icon: <StatsIcon color="inherit" />,
        component: OrderMedia,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/order/:orderId/text",
        name: "Ordre tekst",
        key: "ordreText",
        rtlName: "ordreText",
        icon: <StatsIcon color="inherit" />,
        component: OrderText,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/order/:paramsOrderId/planning",
        name: "Kunde ordre info",
        key: "customerOrderInfo",
        rtlName: "Kunde Order info",
        icon: <StatsIcon color="inherit" />,
        component: Dashboard,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/order/:orderId/messages",
        key: "order_messages",
        name: "BeskederOrdre",
        rtlName: "BeskederOrdre",
        icon: <SupportIcon color="inherit" />,
        component: Messages,
        layout: "/admin",
        protected: false,
        show: false,
      },
    ]
  },

  
  {
    path: "/messages",
    key: "messages",
    name: "Beskeder",
    rtlName: "Beskeder",
    icon: <MessageIcon color="inherit" />,
    component: Messages,
    layout: "/admin",
    protected: false,
    show: true,
  },
  /*
  {
    path: "/kunder",
    key: "kunder",
    name: "Kunder",
    rtlName: "Kunder",
    icon: <CustomerIcon color="inherit" />,
    component: Customers,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "customer",
    path: "/customer",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [
      {
        path: "/customer/:customerId/info",
        name: "Kunde info",
        key: "customerInfo",
        rtlName: "Kunde info",
        icon: <StatsIcon color="inherit" />,
        component: CustomerInfo,
        layout: "/admin",
        protected: true,
        show: true,
      }
    ]
  },
  */
  {
    path: "/reports",
    key: "rapporter",
    name: "Rapporter",
    rtlName: "Rapporter",
    accessLevel: ["ADMIN","PHOTOGRAPHY_COMPANY_ADMIN"],
    icon: <ReportIcon color="inherit" />,
    component: Reports,
    layout: "/admin",
    protected: true,
    show: true,
  },
  {
    name: "report",
    path: "/report",
    collapse: true,
    show: false,
    icon: <DocumentIcon color="inherit" />,
    items: [

      {
        path: "/report/product_turnover",
        name: "produktomsætning",
        key: "produktomsætning",
        rtlName: "produktomsætning",
        icon: <StatsIcon color="inherit" />,
        component: ProductTurnoverReportDetailed,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/report/billingreport",
        name: "faktureringsrapport",
        key: "faktureringsrapport",
        rtlName: "faktureringsrapport",
        icon: <StatsIcon color="inherit" />,
        component: BillingReport,
        layout: "/admin",
        protected: true,
        show: true,
      },
      {
        path: "/report/globalsubcontractorbilling",
        name: "globalsubcontractorbilling",
        key: "globalsubcontractorbilling",
        rtlName: "globalsubcontractorbilling",
        icon: <StatsIcon color="inherit" />,
        component: GlobalSubcontractorBilling,
        layout: "/admin",
        protected: true,
        show: true,
      },
    ],

  },
  {
    path: "/helpcenter",
    key: "helpcenter",
    name: "Hjælpecenter",
    rtlName: "Hjælpecenter",
    icon: <HelpIcon color="inherit" />,
    component: HelpCenter,
    layout: "/admin",
    protected: false,
    show: true,
  },
  {
    path: "/settings",
    key: "settings",
    name: "Indstillinger",
    rtlName: "Indstillinger",
    icon: <SupportIcon color="inherit" />,
    component: Orders,
    layout: "/admin",
    protected: true,
    show: true,
    accessLevel: ["ADMIN","PHOTOGRAPHY_COMPANY_ADMIN"],
    collapse: true,
    items: [
      {
        name: "Profil",
        path: "/setting/profile",
        component: Profile,
        layout: "/admin",
        show: true,
      },
      {
        name: "Brugere",
        path: "/setting/users",
        component: Users,
        layout: "/admin",
        show: true,
      },
      {
        name: "Brugere",
        path: "/setting/user/:userId",  
        component: UserInfo,
        layout: "/admin",
        show: false,
      },
      {
        name: "Kunder",
        path: "/setting/customers",  
        component: Customers,
        layout: "/admin",
        show: true,
      },
      {
        name: "Kunde info",
        path: "/setting/customer/:customerId",  
        component: CustomerInfo,
        layout: "/admin",
        show: false,
      },
      {
        name: "Leverandører",
        path: "/setting/subcontractors",
        component: Subcontractors,
        layout: "/admin",
        show: true,
      },
      {
        name: "Underleverandør",
        path: "/setting/subcontractor/:subcontractorId",  
        component: SubcontractorInfo,
        layout: "/admin",
        show: false,
      },
      
      {
        name: "Standard beskeder",
        path: "/setting/messages",
        component: StandardMessages,
        layout: "/admin",
        show: true,
      },
      {
        name: "Standard beskede",
        path: "/setting/message/:messageType",
        component: StandardMessage,
        layout: "/admin",
        show: false,
      },
      
      {
        name: "Produkter",
        path: "/setting/products",
        component: Products,
        layout: "/admin",
        show: true,
      },
      {
        name: "Produkter",
        path: "/setting/product/:productId",
        component: Product,
        layout: "/admin",
        show: false,
      },
      {
        name: "Tillægsprodukter",
        path: "/setting/extraserviceproducts",
        component: ExtraServiceProducts,
        layout: "/admin",
        show: true,
      },
      {
        name: "Produktkategori",
        path: "/setting/productCategory/:productCategoryId",
        component: ProductCategory,
        layout: "/admin",
        show: false,
      },
      
      {
        name: "Integrationer",
        path: "/setting/integrations",
        component: Integrations,
        layout: "/admin",
        show: true,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/mindworking",
        component: Mindworking,
        layout: "/admin",
        show: false,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/leadhub",
        component: LeadHub,
        layout: "/admin",
        show: false,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/cb",
        component: CB,
        layout: "/admin",
        show: false,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/dinero",
        component: Dinero,
        layout: "/admin",
        show: false,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/economic",
        component: Economic,
        layout: "/admin",
        show: false,
      },
      {
        name: "Integrationer",
        path: "/setting/integration/autobooking",
        component: Magnamulti_Autobooking,
        layout: "/admin",
        show: false,
      },
      {
        name: "Tekst profiler",
        path: "/setting/textprofiles",
        component: TextProfiles,
        layout: "/admin",
        show: true,
      },
      {
        name: "Tekst profil",
        path: "/setting/textprofile/:textProfileId",
        component: TextProfile,
        layout: "/admin",
        show: false,
      },

      {
        protected: true,
        show: true,
        accessLevel: ["ADMIN"],
        name: "Fotografvirksomheder",
        path: "/setting/photographyCompanies",
        component: PhotographyCompanies,
        layout: "/admin",
      },

      
    ]
  },
  /*
  {
    path: "/indstillinger",
    key: "indstillinger",
    name: "Indstillinger",
    rtlName: "Indstillinger",
    icon: <SupportIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
    protected: true,
    show: true

  },
  */
 
  {
    path: "/newpassword/:userId/:token",
    name: "NewPassword",
    rtlName: "NewPassword",
    icon: <DocumentIcon color="inherit" />,
    component: ChangePassword,
    layout: "/auth",
    show: false,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    rtlName: "ForgotPassword",
    icon: <DocumentIcon color="inherit" />,
    component: ForgotPassword,
    layout: "/auth",
    show: false,
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "Login",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    show: false,
  },
  

  /*
  ,{
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    collapse: true,
    items: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
        protected: true,
      },
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
    ],
  },
  */
];
export default dashRoutes;
