// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";
import { FaRegImages, FaArrowLeft, FaFileWord, FaRegCommentAlt } from "react-icons/fa";
import backgroundHeader from "./../../../../assets/img/ProfileBackground.png";

const OrderHeader = ({
  name,
  description,
  onMediaButtonClicked,
  showMediaButton,
  showBackButton,
  showTextButton,
  onTextButtonClicked,
  customButtonShow,
  customButtonIcon,
  customButtonOnClick,
  customButtonText,
  onMessagesButtonClicked,
  showMessagesButton,
  unReadMessages
}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const backgroundProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  return (
    <Box
      mb={{ sm: "40px", md: "75px", xl: "70px" }}
      borderRadius="15px"
      px="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      align="center"
    >
      <Box
        bgImage={backgroundHeader}
        filter="grayscale(1)"
        w="100%"
        h="200px"
        borderRadius="25px"
        bgPosition="50%"
        bgRepeat="no-repeat"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx="1.5rem"
          maxH="330px"
          w={{ sm: "90%", xl: "95%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align="center"
          backdropFilter="saturate(200%) blur(50px)"
          position="absolute"
          boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
          border="2px solid"
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p="24px"
          borderRadius="20px"
          transform={{
            sm: "translateY(5%)",
            md: "translateY(70%)",
            lg: "translateY(70%)",
          }}
        >
          <Flex
            align="center"
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}
          >


            <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight="bold"
                ms={{ sm: "8px", md: "0px" }}
              >
                {name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight="semibold"
              >
                {description}
              </Text>
              {showBackButton && (
                <Text
                  fontSize={"12px"}
                  color={textColor}
                  fontWeight="bold"
                  cursor="pointer"
                  onClick={() => window.history.back()}
                  style={{ position: "absolute" }}
                  top={{ sm: "10px",md: "130px", xl: "109px" }}
                  left={{ sm: "10px"}}
                  
                >
                  <Icon as={FaArrowLeft} me="6px" style={{ position: "relative", top: "1px" }} /> Tilbage
                </Text>
              )}
            </Flex>
          </Flex>

          {customButtonShow && (
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button
                p="0px"
                bg="transparent"
                _hover={{ bg: "none" }}
                variant="transparent-with-icon"
              >
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "100%" }}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={customButtonOnClick}
                >
                  <Icon as={customButtonIcon} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    {customButtonText}
                  </Text>
                </Flex>
              </Button>
            </Flex>
          )}

          {showMessagesButton && (
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button
                p="0px"
                bg="transparent"
                _hover={{ bg: "none" }}
                variant="transparent-with-icon"
              >
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "135px" }}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={() =>
                    onMessagesButtonClicked()
                  }
                >
                  <Icon as={FaRegCommentAlt} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    Beskeder
                  </Text>
                  {unReadMessages > 0 && (
                    <Badge ml='1' variant='solid' colorScheme='green'>{unReadMessages}</Badge>
                  )}
                </Flex>
              </Button>
            </Flex>
          )}

          {showMediaButton && (
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button
                p="0px"
                bg="transparent"
                _hover={{ bg: "none" }}
                variant="transparent-with-icon"
              >
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "135px" }}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={() =>
                    onMediaButtonClicked()
                  }
                >
                  <Icon as={FaRegImages} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    Billeder og video´er
                  </Text>
                </Flex>
              </Button>
            </Flex>
          )}

          {showTextButton && (
            <Flex
              direction={{ sm: "column", lg: "row" }}
              w={{ sm: "100%", md: "50%", lg: "auto" }}
            >
              <Button
                p="0px"
                bg="transparent"
                _hover={{ bg: "none" }}
                variant="transparent-with-icon"
              >
                <Flex
                  align="center"
                  w={{ sm: "100%", lg: "135px" }}
                  transition="all .5s ease"
                  borderRadius="15px"
                  justifyContent="center"
                  py="10px"
                  cursor="pointer"
                  onClick={() =>
                    onTextButtonClicked()
                  }
                >
                  <Icon as={FaFileWord} me="6px" />
                  <Text fontSize="xs" color={textColor} fontWeight="bold">
                    Tekster
                  </Text>
                </Flex>
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default OrderHeader;
