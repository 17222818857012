// Chakra imports
import { Flex, Text, useColorModeValue, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
import CardBody from "./../../../../components/Card/CardBody";
import CardHeader from "./../../../../components/Card/CardHeader";
import { useHistory } from "react-router-dom";
//import { gql, useMutation } from "@apollo/client";

/*
const UPDATE_ORDER_GQL = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;
*/

const OrderCustomer = ({ data }) => {
  const history = useHistory();
  ///const toast = useToast();
  // Chakra color mode
  //const [updateOrderInfo] = useMutation(UPDATE_ORDER_GQL);
  const textColor = useColorModeValue("gray.700", "white");
  //const toast = useToast();

  /*
  const updateData = (orderId, field, data) => {
    let inputData = {};
    inputData.id = orderId;
    inputData[field] = data;

    updateOrderInfo({
      variables: { input: inputData },
    })
      .then(() => {
        dataChanged();
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Opdatering af ordren fejlede.",
          description: "Det var ikke muligt at opdatere ordren.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });

  };
  */


  const handleCustomerClick = () => {
    history.push(`/admin/setting/customer/${data.customer.id}`);
  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} style={{ height: "100%" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Ejendomsmægler information
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                  Navn:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"flex-start"}>
                <Text className="mobileTextDataField" fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"120px"}>
                  {data.customer.name}
                </Text>
                <svg style={{ cursor: 'pointer' }} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCustomerClick}>
                  <g id="Interface / External_Link">
                    <path id="Vector" d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
              </GridItem>
            </Grid>
          </Flex>
          <Flex align="center" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                  Kontakt person:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={'flex-start'}>
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
                  {data.estateAgentName}
                </Text>
              </GridItem>
            </Grid>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                  Kontakt telefon nr.:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={'flex-start'}>
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
                  {data.estateAgentPhone}
                </Text>
              </GridItem>
            </Grid>
          </Flex>
          <Flex align="flex-start" mb="18px">
            <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                <Text fontSize="md" color={textColor} fontWeight="bold" me="10px" whiteSpace={"nowrap"} minWidth={{ sm: "120px", md: "150px", xl: "150px" }}>
                  Kontakt e-mail:{" "}
                </Text>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={'flex-start'}>
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"130px"}>
                  {data.estateAgentEmail}
                </Text>
              </GridItem>
            </Grid>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default OrderCustomer;
