import { useQuery, gql } from "@apollo/client";
import { ClockIcon } from "./../../../../components/Icons/Icons";
import { Flex, Icon, useColorModeValue, Stack, Text } from "@chakra-ui/react";
import Card from "./../../../../components/Card/Card.js";
import CardBody from "./../../../../components/Card/CardBody.js";
import CardHeader from "./../../../../components/Card/CardHeader.js";
import IconBox from "./../../../../components/Icons/IconBox";

const GET_DATA = gql`
  query GET_Data($input: GetOrdersByUserInput!) {
    getOrdersByUser(input: $input){
      id
      caseNo
      estateAddress
      estateZipCode
      estateCity
      status
      keyCase
      createdAt
    }
  }
`;

const FollowUpOrders = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const iconTeal = useColorModeValue("gray.400", "gray.400");
  const { loading, data } = useQuery(GET_DATA, {
    variables: {
      input: {
        userId: localStorage.getItem("system_user_id"),
        status: ['SELLER_NOT_RESPONDING', 'AWAITING_RESPONSE_FROM_CONTACT_PERSON']
      }
    }
    ,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  if (loading) return (
    <Card w={{ sm: "100%" }} h="375px">
      <CardHeader pt="6px" mb="8px" style={{ justifyContent: 'space-between' }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
        Opfølgning
        </Text>
      </CardHeader>
      <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>

      </CardBody>
    </Card>
  );

  const followUpOrders = data.getOrdersByUser.map(order => {
    let status = 'Kontaktperson træffes ikke';
    if (order.status === 'AWAITING_RESPONSE_FROM_CONTACT_PERSON') {
      status = 'Afventer svar fra kontaktperson';
    }

    let keyCase = '';
    if (order.keyCase === "COORDINATE_WITH_CONTACTPERSON" || order.keyCase === "COORDINATE_WITH_ESTATEAGENT") {
      keyCase = ' 🔑';
    }

    return {
      id: order.id,
      address: order.estateAddress + ", " + order.estateZipCode + ' ' + order.estateCity,
      caseNo: order.caseNo + keyCase,
      title: order.caseNo + ' - ' + order.estateAddress,
      addressLink: '/#/admin/order/' + order.id + '/planning',
      status: status
    }
  });

  return (
    <Card w={{ sm: "100%" }} h="375px">
      <CardHeader pt="6px" mb="8px">
        <Flex direction="column">
          {followUpOrders.length > 5 ? (
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Opfølgning ({followUpOrders.length})
            </Text>
          ) : (
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              Opfølgning
            </Text>
          )}
        </Flex>
      </CardHeader>
      <CardBody position="relative" display="block" height="100%" style={{ overflowY: 'auto' }}>
        <Stack direction="column" spacing="20px" >
          {followUpOrders.map((item, cid) => (
            <Flex data-orderid={item.id} data-ordertitle={item.caseNo + ' - ' + item.address} align="center" key={`followUpEvents-${cid}`} className='draggable-calendar-event'>
              <a href={item.addressLink} target="_self" referrerPolicy="no-referrer" className="upCommingEventLink">
                <IconBox h={"50px"} w={"50px"} bg="gray.100" me="16px">
                  <Icon as={ClockIcon} h={"22px"} w={"22px"} color={iconTeal} />
                </IconBox>
                <Flex direction="column">
                  <Text color={textColor} fontSize="sm" fontWeight="bold" lineHeight={'15px'}>
                    {item.caseNo}<br />{item.address}
                  </Text>
                  <Text color="gray.400" fontSize="sm" fontWeight="normal" lineHeight={'17px'}>
                    {item.status}
                  </Text>
                </Flex>
              </a>
            </Flex>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default FollowUpOrders;
