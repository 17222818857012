// Chakra imports
import { Text, Textarea, Input, Flex, Grid, GridItem, useColorModeValue, useToast, Checkbox } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import { Select } from "chakra-react-select";
import Editor from "./../../../../../components/Editor/Editor";
import { FaSignInAlt } from "react-icons/fa";
import { useAuth } from "../../../../../auth-context/auth.context";

const GET_SUBCONTRACTOR = gql`
query subcontractor($id: ID!){
  subcontractor(id: $id){
    id
    name
    address
    zipCode
    city
    cvr
    email
    phoneNo
    contactPersonName
    bookingEmail
    websiteUrl
    note
    language
    mailSubject
    mailText
    subcontractorType
    createdAt
    updatedAt
    description
    subcontractorUiUrl
    deliveryType
    isGlobal
    price
    priceType
    price2
    priceType2
    description
    longDescription
  }
}`

const UPDATE_SUBCONTRACTOR = gql`
  mutation updateSubcontractor($input: UpdateSubcontractorInput!) {
    updateSubcontractor(input: $input) {
      id
    }
  }
`;

function SubcontractorInfo() {
  const toast = useToast();
  const userData = useAuth()
  const [updateSubcontractor] = useMutation(UPDATE_SUBCONTRACTOR);

  // Chakra color mode
  interface Params {
    subcontractorId: string;
  }

  const { subcontractorId } = useParams<Params>();

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const textColor = useColorModeValue("gray.700", "white");

  const { loading, error, data, refetch } = useQuery(GET_SUBCONTRACTOR, {
    variables: { id: subcontractorId },
    fetchPolicy: "no-cache"
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting subcontractor from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting subcontractor from server (2)</p>);
  }

  var timeout = null;
  const updateText = (field, text) => {
    if (text !== "") {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        updateData(field, text);
      }, 2000);
    }
  };

  const updateData = (field, value) => {
    const dataObj = {}
    dataObj['id'] = data.subcontractor.id;
    if (field === 'zipCodeAndCity') {
      dataObj['zipCode'] = value.match(/\d+/g)[0];
      dataObj['city'] = value.match(/[a-zA-Z]+/g)[0];
    } else {
      dataObj[field] = value;
    }

    if (field === 'price' || field === 'price2') {
      dataObj[field] = parseFloat(value);
    }

    updateSubcontractor({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        if (field === 'subcontractorType' || field === 'isGlobal') {
          refetch();
        }
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const addLinebreaks = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  const subcontractorTypeOptions = () => {
    let options = [];
    options.push({ value: 'AUTHER', label: 'Forfatter' });
    options.push({ value: 'MEDIA_PROCESSOR', label: 'Mediebehandler' });
    return options;
  }

  const deliveryTypeOptions = () => {
    let options = [];
    options.push({ value: 'NORMAL', label: 'Normal - Tilbage i systemet' });
    options.push({ value: 'DIRECT', label: 'Direkte - Tilbage i systemet og direkte til kunden' });
    options.push({ value: 'MANUALY_SELECTED', label: 'Vælg ved oprettelse af ordre (Direkte valgt som standard)' });
    options.push({ value: 'MANUALY_NOT_SELECTED', label: 'Vælg ved oprettelse af ordre (Normalt valgt som standard)' });
    return options;
  }

  const selectedSubcontractorType = () => {
    const subcontractorTypes = subcontractorTypeOptions();
    const subcontractorType = subcontractorTypes.find(type => type.value === data.subcontractor.subcontractorType);
    return subcontractorType;
  }

  const selectedDeliveryType = () => {
    const selectedDeliveryTypes = deliveryTypeOptions();
    const deliveryType = selectedDeliveryTypes.find(type => type.value === data.subcontractor.deliveryType);
    return deliveryType;
  }

  const languageOptions = () => {
    let options = [];
    options.push({ value: 'da-DK', label: 'Dansk' });
    options.push({ value: 'en-GB', label: 'Engelsk' });
    return options;
  }

  const selectedLanguage = () => {
    const languages = languageOptions();
    const language = languages.find(lang => lang.value === data.subcontractor.language);
    return language;
  }

  const handleLoginAs = () => {
    window.open(data.subcontractor.subcontractorUiUrl, '_blank');
  }

  const notesColspan = (userData.user.userType === 'ADMIN' && data.subcontractor.isGlobal) ? 2 : 4;

  const selectedPriceType = (priceType) => {
    switch (priceType) {
      case 'PRICE_PER_IMAGE':
        return [{ value: priceType, label: 'Pris pr. billede' }];
      case 'PRICE_PER_FLOORPLAN':
        return [{ value: priceType, label: 'Pris pr. plantegning' }];
      case 'PRICE_PER_ORDER':
        return [{ value: priceType, label: 'Pris pr. ordre' }];
      case 'NONE':
        return [{ value: priceType, label: '-' }];
      default:
        return [{ value: '', label: '' }];
    }
  }

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={data.subcontractor.name}
        description={'Her kan du redigere underleverandørens data og rettigheder.'}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showUserSentWellcomesMessageButton={false}
        onSentWellcomesMessageClicked={() => { }}
        showBackButton={true}
        customButtonShow={true}
        customButtonIcon={FaSignInAlt}
        customButtonOnClick={() => { handleLoginAs() }}
        customButtonText={"Login som underleverandør"}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(4, 1fr)", xl: "repeat(4, 1fr)" }} gap="24px">
        {(userData.user.userType !== 'ADMIN' && data.subcontractor.isGlobal) ? (
          <GridItem colSpan={4} rowSpan={0} >
            <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
              <CardHeader p="12px 5px" mb="12px">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Beskrivelse
                </Text>
              </CardHeader>
              <CardBody px="5px">
                <Flex direction="column" style={{ width: "100%" }}>
                  <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                    <Text fontSize="md" color={textColor} minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    
                      <div style={{textWrap: "wrap"}} dangerouslySetInnerHTML={{ __html: data.subcontractor.longDescription.replace(/\n/g, '<br>') }}></div>
                
                    </Text>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </GridItem>
        ) : (
          <>
            <GridItem colSpan={{ sm: 4, md: 2, xl: 2 }} rowSpan={0}  >
              <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                <CardHeader p="12px 5px" mb="12px">
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                    Underleverandør
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Navn:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="name"
                            defaultValue={data.subcontractor.name}
                            onBlur={(e) => updateData('name', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Kontakt person:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="contactPersonName"
                            defaultValue={data.subcontractor.name}
                            onBlur={(e) => updateData('contactPersonName', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Kontakt e-mail:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="email"
                            defaultValue={data.subcontractor.email}
                            onBlur={(e) => updateData('email', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Vejnavn og nr:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="address"
                            defaultValue={data.subcontractor.address}
                            onBlur={(e) => updateData('address', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Post nr. og by:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="zipCodeAndCity"
                            defaultValue={data.subcontractor.zipCode + " " + data.subcontractor.city}
                            onBlur={(e) => updateData('zipCodeAndCity', e.currentTarget.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>

                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            CVR:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="cvr"
                            defaultValue={data.subcontractor.cvr}
                            onBlur={(e) => updateData('cvr', e.currentTarget.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Telefon nr.:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="phoneNo"
                            defaultValue={data.subcontractor.phoneNo}
                            onBlur={(e) => updateData('phoneNo', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Website:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="websiteUrl"
                            defaultValue={data.subcontractor.websiteUrl}
                            onBlur={(e) => updateData('websiteUrl', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem colSpan={{ sm: 4, md: 2, xl: 2 }} rowSpan={0} >
              <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                <CardHeader p="12px 5px" mb="12px">
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                    Indstillinger
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    <Flex align='center' mb='18px'>
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Type:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Select
                            name="subcontractorType"
                            className="selectSubContractorType"
                            defaultValue={selectedSubcontractorType()}
                            options={subcontractorTypeOptions()}
                            placeholder="Vælg type..."
                            closeMenuOnSelect={true}
                            onChange={(obj) => updateData('subcontractorType', obj.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    {selectedSubcontractorType().value === 'MEDIA_PROCESSOR' && (
                      <Flex align="center" mb="18px">
                        <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                          <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                              Levering:{" "}
                            </Text>
                          </GridItem>
                          <GridItem colSpan={5} rowSpan={1}>
                            <Select
                              name="deliveryType"
                              className="deliveryType"
                              defaultValue={selectedDeliveryType()}
                              options={deliveryTypeOptions()}
                              placeholder="Vælg type..."
                              closeMenuOnSelect={true}
                              onChange={(obj) => updateData('deliveryType', obj.value)}
                            />
                          </GridItem>
                        </Grid>
                      </Flex>
                    )}
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Sprog:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Select
                            name="subcontractorType"
                            className="selectSubContractorType"
                            defaultValue={selectedLanguage()}
                            options={languageOptions()}
                            placeholder="Vælg sprog..."
                            closeMenuOnSelect={true}
                            onChange={(obj) => updateData('language', obj.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                            Booking e-mail:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="bookingEmail"
                            defaultValue={data.subcontractor.bookingEmail}
                            onBlur={(e) => updateData('bookingEmail', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    {userData.user.userType === 'ADMIN' && (
                      <Flex align="center" mb="18px">
                        <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                          <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                              Global leverandør:{" "}
                            </Text>
                          </GridItem>
                          <GridItem colSpan={5} rowSpan={1} display={'flex'} alignItems={"center"}>
                            <Checkbox
                              size={"lg"}
                              defaultChecked={data.subcontractor.isGlobal}
                              onChange={(e) => updateData('isGlobal', e.target.checked)}
                            />
                          </GridItem>
                        </Grid>
                      </Flex>
                    )}
                    {(userData.user.userType === 'ADMIN' && data.subcontractor.isGlobal) && (
                      <>
                        <Flex align="center" mb="18px">
                          <Grid templateColumns={{ base: "1fr", sm: "repeat(6, 1fr)", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                                Pris 1:{" "}
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Input style={{ width: '100%', marginRight: '20px' }} variant="flushed" type="number" name="price" defaultValue={data.subcontractor.price} onBlur={(e) => updateData('price', e.target.value)} />

                            </GridItem>
                            <GridItem colSpan={3} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Select name="priceType" className="selectSubContractorType" defaultValue={selectedPriceType(data.subcontractor.priceType)} options={[{ value: 'NONE', label: '-' }, { value: 'PRICE_PER_IMAGE', label: 'Pris pr. billede' }, { value: 'PRICE_PER_FLOORPLAN', label: 'Pris pr. plantegning' }, { value: 'PRICE_PER_ORDER', label: 'Pris pr. ordre' }]} placeholder="Vælg type..." closeMenuOnSelect={true} onChange={(obj) => updateData('priceType', obj.value)} />
                            </GridItem>
                          </Grid>
                        </Flex>
                        <Flex align="center" mb="18px">
                          <Grid templateColumns={{ base: "1fr", sm: "repeat(6, 1fr)", md: "repeat(6, 1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                            <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                                Pris 2:{" "}
                              </Text>
                            </GridItem>
                            <GridItem colSpan={2} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Input style={{ width: '100%', marginRight: '20px' }} variant="flushed" type="number" name="price2" defaultValue={data.subcontractor.price2} onBlur={(e) => updateData('price2', e.target.value)} />
                            </GridItem>
                            <GridItem colSpan={3} rowSpan={1} display={'flex'} alignItems={"center"}>
                              <Select name="priceType" className="selectSubContractorType" defaultValue={selectedPriceType(data.subcontractor.priceType2)} options={[{ value: 'NONE', label: '-' }, { value: 'PRICE_PER_IMAGE', label: 'Pris pr. billede' }, { value: 'PRICE_PER_FLOORPLAN', label: 'Pris pr. plantegning' }, { value: 'PRICE_PER_ORDER', label: 'Pris pr. ordre' }]} placeholder="Vælg type..." closeMenuOnSelect={true} onChange={(obj) => updateData('priceType2', obj.value)} />
                            </GridItem>
                          </Grid>
                        </Flex>
                      </>
                    )}


                  </Flex>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem colSpan={notesColspan} rowSpan={0} >
              <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                <CardHeader p="12px 5px" mb="12px">
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                    Noter
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                      <Textarea
                        height={"155px"}
                        name="name"
                        defaultValue={addLinebreaks(data.subcontractor.note)}
                        onBlur={(e) => updateData("note", e.currentTarget.value)}
                      />
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>

            {(userData.user.userType === 'ADMIN' && data.subcontractor.isGlobal) && (
              <>
                <GridItem colSpan={2} rowSpan={0} >
                  <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                    <CardHeader p="12px 5px" mb="12px">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Kort beskrivelse (vises ved leverandør valg)
                      </Text>
                    </CardHeader>
                    <CardBody px="5px">
                      <Flex direction="column" style={{ width: "100%" }}>
                        <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                          <Textarea
                            height={"155px"}
                            name="name"
                            defaultValue={addLinebreaks(data.subcontractor.description)}
                            onBlur={(e) => updateData("description", e.currentTarget.value)}
                          />
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem colSpan={4} rowSpan={0} >
                  <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                    <CardHeader p="12px 5px" mb="12px">
                      <Text fontSize="lg" color={textColor} fontWeight="bold">
                        Beskrivelse (vises klik på leverandøren for detaljer)
                      </Text>
                    </CardHeader>
                    <CardBody px="5px">
                      <Flex direction="column" style={{ width: "100%" }}>
                        <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                          <Textarea
                            height={"155px"}
                            name="name"
                            defaultValue={addLinebreaks(data.subcontractor.longDescription)}
                            onBlur={(e) => updateData("longDescription", e.currentTarget.value)}
                          />
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                </GridItem>
              </>
            )}

            <GridItem colSpan={4} rowSpan={0} >
              <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
                <CardHeader p="12px 5px" mb="12px">
                  <Text fontSize="lg" color={textColor} fontWeight="bold">
                    Standard besked
                  </Text>
                </CardHeader>
                <CardBody px="5px">
                  <Flex direction="column" style={{ width: "100%" }}>
                    <Flex align="center" mb="18px">
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                            Emne:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Input
                            variant="flushed"
                            type="text"
                            name="mailSubject"
                            defaultValue={data.subcontractor.mailSubject}
                            onBlur={(e) => updateData('mailSubject', e.target.value)}
                          />
                        </GridItem>
                      </Grid>
                    </Flex>
                    <Flex align="top" mb="18px" >
                      <Grid templateColumns={{ sm: "1fr", md: "repeat(1fr)", xl: "repeat(6, 1fr)" }} width={'100%'}>
                        <GridItem colSpan={1} rowSpan={1} display={'flex'} alignItems={"center"}>
                          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"100px"} me="10px" whiteSpace={"nowrap"}>
                            Besked:{" "}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={5} rowSpan={1}>
                          <Editor id="email_123" value={data.subcontractor.mailText} changed={(e) => (updateText('mailText', e))} showFields={true} />
                        </GridItem>
                      </Grid>

                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            </GridItem>
          </>
        )}
      </Grid>
    </Flex>
  );
}

export default SubcontractorInfo;
