/**
 * @function DawaAutocomplete2
 */
import * as React from 'react'
import classNames from 'classnames'
import styles from './styles.css'
import * as DawaAutocomplete from 'dawa-autocomplete2'
import { Input } from "@chakra-ui/react";
//import { dawaAutocomplete } from 'dawa-autocomplete2'
//const dawaAutocomplete2 = require('dawa-autocomplete2');
interface Selected {
  caretpos: number
  data: {
    dør: string
    etage: number
    href: string
    husnr: string
    id: string
    postnr: string
    postnrnavn: string
    stormodtagerpostnr: string
    stormodtagerpostnrnavn: string
    supplerendebynavn: string
    vejnavn: string
  }
  forslagstekst: string
  stormodtagerpostnr: boolean
  tekst: string
  type: string
}

interface SelectedCallback {
  (selected: Selected): void
}

interface Classes {
  root?: string
  input?: string
  ul?: string
  li?: string
  selected?: string
}

interface Props {
  classes?: Classes
  select: SelectedCallback
  blur: any
  baseUrl?: URL
  adgangsadresserOnly?: boolean
  fuzzy?: boolean
  params?: any
  stormodtagerpostnumre?: boolean
  minLength?: number
  multiline?: boolean
  id?: string
  inputValue?: string
  disabled?: boolean
}

function DawaAutocomplete2(props: Props) {
  const { classes, inputValue, ...rest } = props
  const containerEl = React.createRef<HTMLDivElement>()
  const inputEl = React.createRef<HTMLInputElement>()

  const mutationCallback = (mutationsList: any) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        if (mutation.target.classList.contains('dawa-selected')) {
          mutation.target.classList.remove('dawa-selected')
          mutation.target.classList.add(styles['dawa-selected'])
          if (classes && classes.selected) {
            mutation.target.classList.add(classes.selected)
          }
        }
        if (
          classes &&
          classes.ul &&
          mutation.target.classList.contains('dawa-autocomplete-suggestions') &&
          !mutation.target.classList.contains(classes.ul)
        ) {
          mutation.target.classList.add(classes.ul)
        }
        if (
          classes &&
          classes.li &&
          mutation.target.classList.contains('dawa-autocomplete-suggestion') &&
          !mutation.target.classList.contains(classes.li)
        ) {
          mutation.target.classList.add(classes.li)
        }
      }
    }
  }

  React.useEffect(() => {
    new MutationObserver(mutationCallback).observe(containerEl.current!, {
      attributes: true,
      childList: true,
      subtree: true,
    })
    //const dawaAutocomplete2 = require('dawa-autocomplete2')
    const component = DawaAutocomplete.dawaAutocomplete(inputEl.current, {
      ...rest,
    })

    return () => {
      component.destroy()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames(styles['autocomplete-container'], classes && classes.root)} ref={containerEl}>
      <Input
        variant="flushed"
        type="text"
        autoComplete='off'
        role='presentation'
        disabled={props.disabled}
        className={classNames(classes && classes.input)}
        defaultValue={inputValue}
        ref={inputEl}
        onBlur={(e) => {
          props.blur(e.target.value)
        }}
      />
    </div>
  )
}

export default DawaAutocomplete2
