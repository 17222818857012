// Chakra imports
import { Text, Textarea, Input, Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import ProfileBgImage from "./../../../../../assets/img/ProfileBackground.png";
import SettingsHeader from "../../components/SettingsHeader";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Card from "./../../../../../components/Card/Card";
import CardBody from "./../../../../../components/Card/CardBody";
import CardHeader from "./../../../../../components/Card/CardHeader";
import { Select } from "chakra-react-select";
import React from "react";
import DawaAutocomplete2 from "./../../../../../components/dawa-autocomplete2";
import WorkingHours from "./../../components/workingHours/workingHours";

const GET_USER = gql`
query user($id: ID!){
  user(id: $id){
    id
    name
    phoneNo
    email
    note
    userType
    fullAddress
    calenderColor
    onlyAssignedOrders
    useWorkingHours
    workingHours {
      id
      day_of_week
      start_time
      end_time
      is_closed
    }
    createdAt
    updatedAt
  }
}`

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

const SENT_WELCOMEMAIL_USER = gql`
  mutation sentWelcomeMail($id: ID!) {
    sentWelcomeMail(id: $id)
  }
`;

function UserInfo() {
  const toast = useToast();
  const [selectedUserType, setSelectedUserType] = React.useState(null);
  const [updateUser] = useMutation(UPDATE_USER);
  const [sentWelcomeMail] = useMutation(SENT_WELCOMEMAIL_USER);

  const colorOptions = [
    { value: "blue", label: "Blå" },
    { value: "purple", label: "Lella" },
    { value: "red", label: "Rød" },
    { value: "orange", label: "Orange" },
    { value: "yellow", label: "Gul" },
    { value: "green", label: "Grøn" },
    { value: "pink", label: "Pink" },
    { value: "cyan", label: "Cyan" },
    { value: "teal", label: "Teal" }
  ];
  const [selectedColor, setSelectedColor] = React.useState({ value: "", label: "" });

  // Chakra color mode
  interface Params {
    userId: string;
  }

  const { userId } = useParams<Params>();

  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const textColor = useColorModeValue("gray.700", "white");

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId },
    fetchPolicy: "no-cache"
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return (<p>Error getting user from server (1)</p>); //TODO: make error component
  }

  if (!data) {
    return (<p>Error getting user from server (2)</p>);
  }

  if (!selectedUserType) {
    setSelectedUserType(data.user.userType);
  }

  const handleSentWelcomeMailClicked = () => {
    sentWelcomeMail({ variables: { id: userId } }).then(() => {
      toast({
        title: "Der er nu sendt en velkomst mail til brugeren.",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, der blev IKKE sendt en velkomst mail til brugeren.",
          description: "",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  const updateData = (field, value) => {
    const dataObj = {}
    dataObj['id'] = data.user.id;
    dataObj[field] = value;

    if (field === 'userType') {
      setSelectedUserType(value);
    }

    updateUser({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  const addLinebreaks = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  const handleColorChange = () => {
    if (selectedColor.value === data.user.calenderColor) {
      return;
    }
    updateData('calenderColor', selectedColor.value);
  }

  const selectedUserCalenderColor = (selectedColor) => {
    //const selectedColor =  colorOptions.find(option => option.value === data.user.calenderColor);
    setSelectedColor(selectedColor)
  }

  const userRoleOptions = () => {
    let options = [];
    if (data.user.userType === 'ADMIN') {
      options.push({ value: 'ADMIN', label: 'System administator' });
    }

    options.push({ value: 'PHOTOGRAPHY_COMPANY_ADMIN', label: 'Fotograf administator' });
    options.push({ value: 'PHOTOGRAPHER', label: 'Fotograf' });
    return options;
  }

  const selectedUserRole = () => {
    const userRoles = userRoleOptions();
    const selectedRole = userRoles.find(role => role.value === data.user.userType);
    return selectedRole;
  }


  const onlyAssignedOrdersOptions = () => {
    let options = [];
    options.push({ value: false, label: 'Nej' });
    options.push({ value: true, label: 'Ja' });
    return options;
  }

  const selectedonlyAssignedOrders = () => {
    const userRoles = onlyAssignedOrdersOptions();
    const selectedRole = userRoles.find(role => role.value === data.user.onlyAssignedOrders);
    return selectedRole;
  }

  if (selectedColor.value === "") {
    const Color = colorOptions.find(option => option.value === data.user.calenderColor);
    setSelectedColor(Color);
  }


  const handleCurrentAddressChanged = (fulladdress: string) => {
    updateData('fullAddress', fulladdress)
    console.log('fulladdress:', fulladdress)
    //setCurrentAddress(fulladdress)
  }

  return (
    <Flex direction="column" pt={{ base: "20px", md: "35px" }}>
      <SettingsHeader
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        name={data.user.name}
        description={'Her kan du redigere brugerens data og rettigheder.'}
        showCreateUserButton={false}
        onCreateUserClicked={() => { }}
        showUserSentWellcomesMessageButton={true}
        onSentWellcomesMessageClicked={handleSentWelcomeMailClicked}
        showBackButton={true}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="24px">
        <GridItem colSpan={2} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Bruger
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    navn:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="name"
                    defaultValue={data.user.name}
                    onBlur={(e) => updateData('name', e.target.value)}
                  />
                </Flex>


                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Adresse:{" "}
                  </Text>
                  <DawaAutocomplete2
                    select={(selected) => {
                      handleCurrentAddressChanged(selected.tekst)
                    }}
                    blur={(address: string) => {
                      handleCurrentAddressChanged(address)
                    }}
                    inputValue={data.user.fullAddress.replace(
                      '-',
                      '',
                    )}
                    classes={{
                      root: 'autocomplete-dawa-container',
                      input: 'text-field-dawa', 
                      ul: 'ul',
                      li: 'li',
                      selected: 'selected',
                    }}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    E-mail:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="email"
                    defaultValue={data.user.email}
                    onBlur={(e) => updateData('email', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Telefon nr.:{" "}
                  </Text>
                  <Input
                    variant="flushed"
                    type="text"
                    name="phoneNo"
                    defaultValue={data.user.phoneNo}
                    onBlur={(e) => updateData('phoneNo', e.target.value)}
                  />
                </Flex>
                <Flex align="center" mb="18px">
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Kalender farve:{" "}
                  </Text>
                  <Select
                    name="colors"
                    selectedOptionColor={selectedColor.value}
                    className="selectUserCalenderColor"
                    options={colorOptions}
                    placeholder="Vælg farve..."
                    closeMenuOnSelect={false}
                    value={selectedColor}
                    onChange={(obj) => selectedUserCalenderColor(obj)}
                    onMenuClose={() => {
                      handleColorChange();
                    }}
                  />
                </Flex>
                <Flex align='center' mb='18px'>
                  <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"130px"} me="10px" whiteSpace={"nowrap"}>
                    Type:{" "}
                  </Text>
                  <Select
                    name="userType"
                    className="selectUserType"
                    defaultValue={selectedUserRole()}
                    options={userRoleOptions()}
                    placeholder="Vælg rolle..."
                    closeMenuOnSelect={true}
                    onChange={(obj) => updateData('userType', obj.value)}
                  />
                </Flex>

                {selectedUserType === 'PHOTOGRAPHER' && (
                  <Flex align='center' mb='18px'>
                    <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
                      Kan kun se tildelte sager:{" "}
                    </Text>
                    <Select
                      name="onlyAssignedOrders"

                      className="selectUserType"
                      defaultValue={selectedonlyAssignedOrders()}
                      options={onlyAssignedOrdersOptions()}
                      placeholder="Vælg rolle..."
                      closeMenuOnSelect={true}
                      onChange={(obj) => updateData('onlyAssignedOrders', obj.value)}
                    />
                  </Flex>
                )}
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem colSpan={0} rowSpan={0} >
          <WorkingHours active={data.user.useWorkingHours} userId={data.user.id} workingHours={data.user.workingHours} />
        </GridItem>
        <GridItem colSpan={3} rowSpan={0} >
          <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
            <CardHeader p="12px 5px" mb="12px">
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                Noter
              </Text>
            </CardHeader>
            <CardBody px="5px">
              <Flex direction="column" style={{ width: "100%" }}>
                <Flex align="center" mb="18px" alignItems={"Top"} height={"100%"}>
                  <Textarea
                    height={"270px"}
                    name="name"
                    defaultValue={addLinebreaks(data.user.note)}
                    onBlur={(e) => updateData("note", e.currentTarget.value)}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </GridItem>
        
      </Grid>
    </Flex>
  );
}

export default UserInfo;
