// Chakra imports
import { Flex, Text, useColorModeValue, useToast, Select } from "@chakra-ui/react";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import { gql, useMutation } from "@apollo/client";

const updatePhotographyCompanyInfoGql = gql`
  mutation UpdatePhotographyCompanyInput($input: UpdatePhotographyCompanyInput!) {
    updatePhotographyCompany(input: $input) {
      id
    }
  }
`;


const ProfileSettings = ({ data, forceRerender }) => {
  const toast = useToast();
  const [updateCustomerInfo] = useMutation(updatePhotographyCompanyInfoGql);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  const updateData = (field, value) => {
    const dataObj = {}
    dataObj['id'] = data.id;
    dataObj[field] = value;
    if (field === 'billingPeriodStart') {
      dataObj[field] = parseInt(value);
    }
    
    updateCustomerInfo({ variables: { input: dataObj } })
      .then(() => {
        toast({
          title: "Ændringen er blevet gemt.",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        forceRerender();
      })
      .catch(() => {
        toast({
          title: "Der opstod en fejl, ændringen blev ikke gemt.",
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });

  };

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }} height="100%">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Indstillinger
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column" style={{ width: "100%" }}>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={"200px"} me="10px" whiteSpace={"nowrap"}>
              Faktureringsperiode start:{" "}
            </Text>
            <Select variant="flushed" name="billingPeriodStart" defaultValue={data.billingPeriodStart} onChange={(e) => updateData('billingPeriodStart', e.currentTarget.value)}>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </Select>
          </Flex>
          

        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProfileSettings;
