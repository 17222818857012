// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Select,
  Icon
} from "@chakra-ui/react";
import { FaPlusCircle  } from "react-icons/fa";
// Custom components
import Card from "../../../../../components/Card/Card";
import CardBody from "../../../../../components/Card/CardBody";
import CardHeader from "../../../../../components/Card/CardHeader";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
//import { useTranslation } from "react-i18next";
import SettleTable from "../../../../../components/Tables/SettleTable";
import mwLogo from "./../../../../../assets/svg/Mindworking-logo.svg";


const createTextSectionGql = gql`
  mutation createTextSection($input: CreateTextSectionInput!) {
    createTextSection(input: $input) {
      id
    }
  }
`;

const deleteTextSectionGql = gql`
  mutation deleteTextSection($id: ID!) {
    deleteTextSection(id: $id)
  }
`;

const updateTextSectionGql = gql`
  mutation updateTextSection($input: UpdateTextSectionInput!) {
    updateTextSection(input: $input) {
      id
    }
  }
`;

const updateTextSectionSortingGql = gql`
  mutation updateTextSectionSorting($input: [UpdateTextSectionSortingInput]) {
    updateTextSectionSorting(input: $input) 
  }
`;

const getSectionsFromExternalSystemGql = gql`
  mutation updateTextProfileFromIntegraion($input: updateTextProfileFromIntegraionInput!) {
    updateTextProfileFromIntegraion(input: $input) 
  }
`;

const CreateTextSectionObj = {
  name: '' as string,
  textType: 'TEXT' as string,
  textLength: 100 as number,
  externalRef: '' as string
}

interface IProps {
  textSections: any[]
  forceRerender: any
  profileId: String
  integrations: any[]
  customers: any[]
}

const TextSectionList: React.FC<IProps> = ({ textSections, integrations, forceRerender, profileId, customers }) => {
  const toast = useToast();
  //const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAddTextSectionDialogOpen, onOpen: onAddTextSectionDialogOpen, onClose: onAddTextSectionDialogClose } = useDisclosure();
  const { isOpen: isGetSectionsFromExternalSystemDialogOpen, onOpen: onGetSectionsFromExternalSystemModalOpen, onClose: onGetSectionsFromExternalSystemModalClose } = useDisclosure();


  const cancelRef = React.useRef();
  const [createTextSection] = useMutation(createTextSectionGql);
  const [deleteTextSection] = useMutation(deleteTextSectionGql);
  const [updateTextSection] = useMutation(updateTextSectionGql);
  const [updateTextSectionSorting] = useMutation(updateTextSectionSortingGql);
  const [getSectionsFromExternalSystem] = useMutation(getSectionsFromExternalSystemGql);
  const [selectedTextSection, setSelectedTextSection] = useState<any>();
  const [selectedExternalSystem, setSelectedExternalSystem] = useState<any>('');
  const [selectedExternalSystemCustomer, setSelectedExternalSystemCustomer] = useState<any>('');
  const [externalSystemCustomers, setExternalSystemCustomers] = useState<any>([]);
  const textColor = useColorModeValue("gray.700", "white");



  const handleGetSectionsFromExternalSystemClicked = (integrationType) => {
    setSelectedExternalSystem(integrationType);

    //fileter customers, based on mwSystemName is not empty or null
    const filteredCustomers = customers.filter((customer) => customer.mwSystemName !== null && customer.mwSystemName !== '');

    setExternalSystemCustomers(filteredCustomers);
    onGetSectionsFromExternalSystemModalOpen();
  };

  const onUpdateSectionsfromExternalSystemClicked = () => {
    getSectionsFromExternalSystem({
      variables: {
        input: {
          id: profileId,
          integration: selectedExternalSystem,
          externalSolution: selectedExternalSystemCustomer
        },
      },
    }).then(() => {
      forceRerender()
    });
  };

  const handleAddTextSectionClicked = () => {

    CreateTextSectionObj.name = '';
    CreateTextSectionObj.textType = 'TEXT';
    CreateTextSectionObj.textLength = 100;
    //setCreateTextSectionData(CreateTextSectionObj);
    onAddTextSectionDialogOpen();
  };

  const onAddTextSectionClicked = () => {

    if (selectedTextSection) {
      //update
      const inputData = {
        id: selectedTextSection.id,
        name: CreateTextSectionObj.name,
        textType: CreateTextSectionObj.textType,
        textLength: CreateTextSectionObj.textLength,
        externalRef: CreateTextSectionObj.externalRef
      }
      updateTextSection({ variables: { input: inputData } });
    } else {
      //create
      const inputData = { ...CreateTextSectionObj, profileId: profileId }
      createTextSection({ variables: { input: inputData } });
    }

    onAddTextSectionDialogClose();

    toast({
      title: "Feltet er nu oprettet.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    setSelectedTextSection(null);

    setTimeout(() => {
      forceRerender();
    }, 1000);
  }

  const handleDeleteClick = (selectedObj) => {
    for (let f = 0; f < textSections.length; f++) {
      if (selectedObj.id === textSections[f].id) {
        setSelectedTextSection(textSections[f]);
      }
    }
    onOpen();
  };

  const handleEditClick = (selectedObj) => {
    for (let f = 0; f < textSections.length; f++) {
      if (selectedObj.id === textSections[f].id) {
        setSelectedTextSection(textSections[f]);

        CreateTextSectionObj.name = textSections[f].name;
        CreateTextSectionObj.textType = textSections[f].textType;
        CreateTextSectionObj.textLength = textSections[f].textLength;
        //setCreateTextSectionData(CreateTextSectionObj);
      }
    }

    onAddTextSectionDialogOpen();
  };

  const deleteTextSectionClicked = () => {
    onClose();
    deleteTextSection({ variables: { id: selectedTextSection.id } });

    setTimeout(() => {
      forceRerender();
    }, 200);
    setSelectedTextSection(null);
  };

  const onTextSectionModalClose = () => {
    onAddTextSectionDialogClose();
    setSelectedTextSection(null);
  };

  const updateData = (field, value) => {
    CreateTextSectionObj[field] = value;
    if (field === 'textLength') {
      CreateTextSectionObj[field] = parseInt(value);
    }
    //setCreateTextSectionData(CreateTextSectionObj);
  }

  const setTableData = (obj) => {
    var tableData = obj(textSections);
    let tmptableData = JSON.parse(JSON.stringify(tableData));

    for (var i = 0; i < tmptableData.length; i++) {
      tmptableData[i].sorting = i + 1;
    }

    var result = tmptableData.map((product) => ({ id: product.id, sorting: product.sorting }));

    updateTextSectionSorting({
      variables: {
        input: result,
      },
    }).then(() => {
      forceRerender()
    });
  };

  const convertTextType = (textType) => {
    switch (textType) {
      case 'HEADING':
        return 'Overskrift';
      case 'SUBHEADING':
        return 'Under overskrift';
      case 'TEXT':
        return 'Tekst';
      case 'SOME':
        return 'SoMe tekst';
      case 'SALES_BULLETS':
        return 'Salgspunkter';
      default:
        return '';
    }
  }

  const columns = [
    {
      header: "",
      accessor: "id",
      hidden: true
    },
    {
      header: "Navn",
      accessor: "name",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Type",
      accessor: "fieldType",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Længde",
      accessor: "textLength",
      onClick: handleEditClick,
      width: 200,
    },
    {
      header: "Rediger",
      icon: "edit",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      accessor: "delete",
      onClick: handleEditClick,
    },
    {
      header: "Slet",
      accessor: "2",
      icon: "delete",
      alignHeader: "center",
      alignData: "right",
      width: 100,
      onClick: handleDeleteClick,
    },
  ];

  let tableData = JSON.parse(JSON.stringify(textSections));

  for (var i = 0; i < tableData.length; i++) {
    tableData[i].fieldType = convertTextType(tableData[i].textType);
  }

  const isIntegrationActive = (integrationType) => {
    return integrations.find((integration: any) => integration.integrationType === integrationType) !== undefined;
  }

  const mindWorkingFields = ["MetaPrimaryText", "DisplayHeadline", "MetaLinkDescription", "AnnounceHeadlineInternet1", "AnnounceTextInternet", "AnnounceHeadline1", "AnnounceHeadline2", "AnnounceHeadline3", "AnnounceHeadline4", "AnnounceHeadline5", "AnnounceHeadline6", "AnnounceHeadline7", "AnnounceIntroText1", "AnnounceIntroText2", "AnnounceIntroText3", "AnnounceIntroText4", "AnnounceIntroText5", "AnnounceIntroText6", "AnnounceIntroText7", "AnnounceIntroText8", "AnnounceIntroText9", "AnnounceIntroText10", "AnnounceText1", "AnnounceText2", "AnnounceText3", "AnnounceText4", "AnnounceText5", "AnnounceText6", "AnnounceText7", "AnnounceText8", "AnnounceText9", "AnnounceText10"];
    
  return (
    <Card p="16px">
      <CardHeader style={{ justifyContent: "space-between" }}>
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          Tekst felter
        </Text>
        <div style={{ display: 'flex', gap: '20px' }}>

          {(isIntegrationActive('MINDWORKING') && process.env.REACT_APP_USE_MINDWORKINGV2 === 'true') && (
            <div style={{ display: 'flex', gap: '5px' }}>
              <img src={mwLogo} style={{ height: '20px' }} height={18} width={18} alt='Uploaded til Mindworking' className="mwUploadButtonLogo" />
              <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleGetSectionsFromExternalSystemClicked('MINDWORKING')}>
                Hent/opdaterer felter fra Mindworking
              </Text>
            </div>
          )}
          <div style={{ display: 'flex' }}>
            <Icon as={FaPlusCircle} me="6px" color={"gray"} />
            <Text fontSize="12px" fontWeight="bold" cursor="pointer" onClick={() => handleAddTextSectionClicked()}>
              Tilføj nyt felt
            </Text>
          </div>
        </div>
      </CardHeader>

      <CardBody w="100%" pt="28px" style={{ flexDirection: "column" }}>
        <SettleTable data={tableData} columnsData={columns} csvData={[]} setDragData={setTableData} enableSelect={false} />
      </CardBody>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={deleteTextSectionClicked}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Slet tekst felt
            </AlertDialogHeader>

            <AlertDialogBody>Er du sikker?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fortryd
              </Button>
              <Button colorScheme="red" onClick={deleteTextSectionClicked} ml={3}>
                Slet
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isAddTextSectionDialogOpen} onClose={onTextSectionModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Opret nyt tekst felt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Du er ved at oprettet et nyt tekst felt, udfyld nedenstående og klik opret.</Text>
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Navn:{" "}
                </Text>
                <Input
                  variant="flushed"
                  type="text"
                  name="name"
                  defaultValue={selectedTextSection ? selectedTextSection.name : ""}
                  onBlur={(e) => updateData('name', e.target.value)}
                />
              </Flex>
              {(isIntegrationActive('MINDWORKING') && process.env.REACT_APP_USE_MINDWORKINGV2 === 'true') && (
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Felt i Mindworking:{" "}
                </Text>
                <Select onChange={(e) => updateData('externalRef', e.target.value)} defaultValue={selectedTextSection ? selectedTextSection.externalRef : ""}>
                  <option value=''></option>
                  {mindWorkingFields.map((field) => (
                    <option value={field}>{field}</option>
                  ))}
                </Select>
              </Flex>
              )}


              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Type:{" "}
                </Text>
                <Select onChange={(e) => updateData('textType', e.target.value)} defaultValue={selectedTextSection ? selectedTextSection.textType : ""}>
                  <option value="HEADING">Overskrift</option>
                  <option value="SUBHEADING">Under overskrift</option>
                  <option value="TEXT">Tekst</option>
                  <option value="SOME">SoMe tekst</option>
                  <option value="SALES_BULLETS">Salgspunkter</option>
                </Select>
              </Flex>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Maks antal karakterer:{" "}
                </Text>
                <Input
                  variant="flushed"
                  type="number"
                  name="textLength"
                  defaultValue={selectedTextSection ? selectedTextSection.textLength : "100"}
                  onBlur={(e) => updateData('textLength', e.target.value)}
                />
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onTextSectionModalClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={onAddTextSectionClicked} ml={3}>
              {selectedTextSection ? <div>Gem</div> : <div>Opret</div>}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isOpen={isGetSectionsFromExternalSystemDialogOpen} onClose={onGetSectionsFromExternalSystemModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Hent tekst indstillinger fra
            {selectedExternalSystem === 'MINDWORKING' ? ' Mindworking' : ''}

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Vælg den kunde du ønsker at hente tekst indstillingerne for.</Text>
            <Text color={'red'} fontSize={'12px'}>* Alle eksisterende data vil blive overskrevet.</Text>
            <br />
            <Flex direction="column" style={{ width: "100%" }}>
              <Flex align="center" mb="18px">
                <Text fontSize="md" color={textColor} me="10px" whiteSpace={"nowrap"} minWidth={"150px"}>
                  Vælg kunde:{" "}
                </Text>
                <Select onChange={(e) => setSelectedExternalSystemCustomer(e.target.value)} >
                  {externalSystemCustomers.map((customer) => (
                    <option value={customer.mwSystemName}>{customer.name}</option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onGetSectionsFromExternalSystemModalClose}>Fortryd</Button>
            <Button colorScheme="green" onClick={onUpdateSectionsfromExternalSystemClicked} ml={3}>Hent</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card >
  );
};

export default TextSectionList;
